import { html} from "lit-element";
import StoreElement from "../../components/StoreElement";

class AddButton extends StoreElement {
    static get properties() {
        return {
            opened: { type: Boolean },
            id: { type: String },
            item: { type: Object },
            labels: { type: Object },
            language: {type: String}
        };
    }

    constructor() {
        super();
        this.opened = false;
        this.editable = false;
        this.item = null;
        this.id = null;
        this.itemLabels = [];
    }

    connectedCallback() {
        super.connectedCallback();
        if (!this.labels)
            return;
        for (let [k, v] of Object.entries(this.labels)) {
            this.itemLabels.push({k, v});
        }
    }

    storeChanged() {
        let state = this.store.getState();
        this.item = state.item;
        this.editable = state.user.rights.write && state.version === "master";
        this.requestUpdate();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }

    saveItem(evt) {
        if(evt) {
            evt.preventDefault();
        }
        let rawData = JSON.parse(JSON.stringify(this.item.details.rawData));
        let namedKey = this.shadowRoot.querySelector("#keyInput").value;
        for (let l of this.itemLabels){
            if(l.v == namedKey){
                namedKey = l.k;
                break;
            }
        }
        rawData[namedKey] =  this.shadowRoot.querySelector("#valueInput").value;
        this.store.dispatch(this.store.thunks.changeItemRawData(this.item.id, this.language, rawData));
        this.opened = false;
    }
 
    reset() {
        this.opened = false;
    }

    toggle(evt) {
        if(evt) {
            evt.stopPropagation();
            evt.preventDefault();
        }
        this.opened = !this.opened;
    }

    render() {
        return html`
        <link rel="stylesheet" href="/css/bootstrap.min.css">
        <link rel="stylesheet" href="/css/materialdesignicons.min.css">
        <button @click=${this.toggle} ?disabled=${!this.editable} ?hidden=${!this.editable}
            class="btn btn-sm btn-outline-info mdi mdi-add">+
        </button>
        <div class="shadow" ?hidden=${!this.opened}  style="position: absolute; z-index: 1000; background-color: #fff;">
            <div class="border border-info p-2">
                <form class="form col" id="form">
                    <div class="form-group row align-items-left">
                        <label class="col-2" for="keyInput">Key</label>
                        <div class="col">
                            <input id="keyInput" name="keyInput" list=dataInput class="form-control" required>
                            <datalist id="dataInput">
                                <select name="keyInput">
                                    ${this.itemLabels?.map(e => html`<option value=${e.v} id=${e.k}>${e.v}</option>`)}
                                </select>
                            </datalist>
                        </div>
                    </div>
                    <div class="form-group row align-items-left">
                        <label class="col-2" for="valueInput">Value</label>
                        <div class="col">
                            <input type="text" class="form-control" id="valueInput" required />
                        </div>
                    </div>
                    <div class="form-group row align-items-right">
                        <div class="col btn-toolbar">
                            <button @click=${this.saveItem} type="submit" class="btn btn-outline-success">Hinzufügen</button>
                        </div>
                        <div class="col btn-toolbar">
                            <button @click=${this.reset} type="reset" class="btn btn-outline-secondary">Abbrechen</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>`;
    }
}


window.customElements.define("add-button", AddButton);

