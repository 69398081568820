import store from "../service/store";
import * as actions from "../service/actionTypes";

export class LoginHandler extends HTMLElement {
    connectedCallback() {
        console.log("LoginHandler is available");
        this._unsub = store.subscribe(this.storeChanged.bind(this));
        this.modal = this.querySelector(this.getAttribute("modal"));
        this.form = this.querySelector(this.getAttribute("form"));
        this.loginButton = this.querySelector(this.getAttribute("login"));
        this.logoutButton = this.querySelector(this.getAttribute("logout"));
        if (!this.form || !this.logoutButton || !this.loginButton) {
            console.error("Misconfiguration in LoginHandler");
            console.error("Form or Logout Button not found. Ensure that the attributes point to the correct elements");
            return;
        }
        this.form.addEventListener("submit", this.login.bind(this));
        this.logoutButton.addEventListener("click", this.logout.bind(this));
        //initialize
        let token = globalThis.localStorage.getItem("token");
        let refreshToken = globalThis.localStorage.getItem("refreshToken");
        if(token) {
            console.log("Found a token", token);
            store.dispatch({type: actions.LOGIN, token, refreshToken } );
        }
    }

    disconnectedCallback() {
        this._unsub?.();
    }

    async login(e) {
        e.preventDefault();
        let error = this.form.querySelector(".alert");
        error.innerHTML = "";
        error.hidden = true;
        let data = new FormData(this.form);
        //convert data to json
        const value = Object.fromEntries(data.entries());
        console.log(`Login with ${JSON.stringify(value)}`);
        let res = await fetch(globalThis.auth_url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "name": value.user,
                "password": value.password
            })
        });

        if (res.ok) { // if HTTP-status is 200-299
            // get the response body (the method explained below)
            let json = await res.json();
            store.dispatch({type: actions.LOGIN, token: json.token, refreshToken: json.refreshToken});
            //TODO: remove jquery
            $(this.modal).modal("hide"); //eslint-disable-line no-undef
        } else {
            //create message, that login failed
            error.innerHTML = res.status;
            error.hidden = false;
            //don't close modal here
        }
    }

    async logout() {
        //just delete the token, this suffices here
        store.dispatch({ type: actions.LOGOUT });
        console.log("Logged out");
    }

    storeChanged() {
        let token = store.getState()?.token;
        console.log(`Updating button states: Token ${token ? "present" : "missing"}`);
        this.logoutButton.hidden = !token;
        this.loginButton.hidden = !!token;
    }
}

window.customElements.define("login-handler", LoginHandler);