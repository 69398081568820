import { html, css } from "lit-element";
import { classMap } from "lit-element/node_modules/lit-html/directives/class-map.js";
import GraphQLHelper from "../service/graphql";
import StoreElement from "../components/StoreElement";

class AssetDialog extends StoreElement {
    static get properties() {
        return {
            opened: { type: Boolean },
            selectedFile: { type: String, attribute: false },
            fileList: { type: Array, attribute: false }
        };
    }

    static get styles() {
        return css`
            ::slotted(input) {
                display: flex;
                flex: 1;
            }
        `;
    }

    constructor() {
        super();
        this.opened = false;
        this.selectedFile = "";
        this.fileList = [];
        this.requestData();
        this.lastUrlObject = null;
    }

    get input() {
        return this.querySelector("input[type=text]");
    }

    get nameInput() {
        return this.shadowRoot.querySelector("#nameInput");
    }

    get fileSelect() {
        return this.shadowRoot.querySelector("#fileSelect");
    }

    get uploadFile() {
        return this.shadowRoot.querySelector("#upload");
    }

    storeChanged() {}

    disconnectedCallback() {
        if (this.lastUrlObject) {
            URL.revokeObjectURL(this.lastUrlObject);
        }
        super.disconnectedCallback();
    }

    load() {
        if(this.opened)
            return;
        this.opened = true;
        if (!this.input.value)
            return;
        let data = JSON.parse(this.input.value);
        this.nameInput = data.name;
        this.selectedFile = data.asset;
        //caching?
        if(!this.fileList) {
            this.requestData();
        }
    }

    async requestData() {
        let res = await GraphQLHelper.query(`query { files { id name url } }`);
        if (res.error) {
            console.log(res.error);
            this.fileList = [];
        } else {
            this.fileList = res.data.files.map(e => {
                return {
                    url: e.url,
                    name: e.name,
                    id: e.id,
                    type: e.url.substring(e.url.lastIndexOf(".") + 1)
                };
            });
            this.changeState({fileList : this.fileList});
        }
    }

    commit() {
        this.input.value = JSON.stringify({
            name: this.nameInput?.value,
            id: this.selectedFile,
            translatable: false
        });
        //manually trigger change event here, as changing value does not create this
        this.input.dispatchEvent(new Event("change"));
    }

    reset() {
        this.opened = false;
    }

    toggle(evt) {
        if(evt) {
            evt.stopPropagation();
            evt.preventDefault();
        }
        this.opened = !this.opened;
    }

    clear(evt) {
        let affirm = confirm("Achtung:\n\nDie Eingaben werden gelöscht");
        if(!affirm)
            return;
        if(evt) {
            evt.stopPropagation();
            evt.preventDefault();
        }
        this.input.value = "";
        this.opened = false;
        this.input.dispatchEvent(new Event("change"));
    }

    async #loadFile() {
        let { token } = this.store.getState();
        let entry = this.fileList.find(e => e.id === this.selectedFile);
        if(!entry)
            return;
        if (entry.url.endsWith(".mp4")) {
            let video = document.createElement("video");
            let videoData = await fetch(entry.url, { headers: { authorization: `Bearer ${token}` } });
            let objectUrl = URL.createObjectURL(await videoData.blob());
            video.setAttribute("controls", true);
            video.style.maxHeight = "100%";
            video.style.maxWidth = "100%";
            video.src = objectUrl;
            if (this.lastUrlObject) {
                URL.revokeObjectURL(this.lastUrlObject);
            }
            this.lastUrlObject = objectUrl;
            this.shadowRoot.querySelector("#preview").replaceChildren(video);
        } else {
            let img = document.createElement("img");
            let imgData = await fetch(entry.url, { headers: { authorization: `Bearer ${token}` } });
            let objectUrl = URL.createObjectURL(await imgData.blob());
            img.src = objectUrl;
            img.style.maxWidth = "100%";
            img.style.maxHeight = "100%";
            img.addEventListener("load", () => {
                if (this.lastUrlObject) {
                    URL.revokeObjectURL(this.lastUrlObject);
                }
                this.lastUrlObject = objectUrl;
                this.shadowRoot.querySelector("#preview").replaceChildren(img);
            });
        }        
    }

    async loadPreview(evt) {
        evt.stopPropagation();
        let url = evt.target.value;
        if (!url) {
            return;
        }
        this.selectedFile = evt.target.selectedOptions[0].getAttribute("data-id");
        //set name
        this.nameInput.value =  evt.target.selectedOptions[0].innerText;
        //also update
        this.commit();
        //finally show
        await this.#loadFile();
    }

    async upload(evt) {
        evt.preventDefault();
        let uploadElement = evt.target.upload;
        if(!uploadElement.validity.valid) {
            alert("Invalid");
            return;
        }
        if(uploadElement.files.length === 0)
            return;
        let res = await GraphQLHelper.upload(`mutation($file: Upload!) {
            uploadFile(file: $file) {
                id
            }
        }`, {
            file: uploadElement.files[0]
        });
        //set the name
        this.nameInput.value =  uploadElement.files[0].name;
        this.selectedFile = res.data.uploadFile.id;
        //check if we need to reload:
        if(!this.fileList.find(e => e.id === res.data.uploadFile.id)) {
            await this.requestData();
        }
        await this.#loadFile(evt);
        //save changes
        this.commit();
    }

    render() {
        let buttonText = this.opened ? "X" : "...";
        return html`
        <link rel="stylesheet" href="/css/bootstrap.min.css">
        <link rel="stylesheet" href="/css/materialdesignicons.min.css">
        <div class="container" style="position: relative; display: inline-block;">
            <div class="row" @click=${this.toggle}>
                <slot class="col"></slot>
                <button class="d-flex btn ${classMap({"btn-outline-secondary": !this.opened, "btn-danger": this.opened})}">${buttonText}</button>
            </div>
            <div class="shadow" ?hidden=${!this.opened} style="position: absolute; z-index: 1000; background-color: #fff;">
                <div class="border border-info p-2">
                    <form class="form col">
                        <div class="form-group row align-items-center">
                            <label class="col-2" for="nameInput">Name</label>
                            <div class="col">
                                <input type="text" class="form-control" id="nameInput" @change=${this.commit} required />
                            </div>
                            <div class="col-2 btn-toolbar">
                                <button class="btn btn-outline-success" @click=${this.toggle}>✓</button>
                                <button class="btn btn-outline-danger mdi mdi-delete" @click=${this.clear}></button>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="fileSelect">Files</label>
                            <div class="form-row align-items-center">
                                <div class="col-6">
                                    <select class="form-control" id="fileSelect" size=10 required @change=${this.loadPreview} >
                                        ${this.fileList.map(e => html`<option data-id=${e.id} value=${e.url} ?selected=${e.id === this.selectedFile}>${e.name} (${e.type})</option>`)}
                                    </select>
                                </div>
                                <div class="col-6">
                                    <div id="preview" class="border border-primary img-fluid text-center" style="height: 15rem;">Preview Area</div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="border border-info p-2">
                    <form class="form col" @submit=${this.upload}>
                        <div class="form-group">
                            <label>Neue Datei hochladen</label>
                            <div class="input-group">
                                <div class="custom-file">
                                    <input type="file" class="form-control-file" id="upload" name="upload" required />
                                </div>
                                <div class="input-group-append">
                                    <button type="submit" class="btn btn-outline-success">Hochladen</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>`;
    }
}


window.customElements.define("asset-dialog", AssetDialog);