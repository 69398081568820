import GraphQLHelper from "../graphql";
import * as actions from "../actionTypes";
//TODO: have a look here -> https://redux-toolkit.js.org/usage/usage-guide#defining-async-logic-in-slices

export function getBooklet(booklet_id) {
    return async(dispatch) => {
        let res = await GraphQLHelper.query(`query BasicBookletData($id: ID!) {
            bookletById(id: $id) {
                id
                berufId
                title
                details
                testHeftId
                testHeftName
                languages
                tags
                competences {
                    id
                    title
                    abbreviation
                    allowedTime
                    label
                    items {
                        item {
                            title
                            id
                        }
                        correct {
                            title
                            id
                        }
                        wrong {
                            title
                            id
                        }
                        countForProgress
                    }
                    screens (language: "de-DE") {
                        id
                        name
                        language
                    }
                }
            }
        }`, { id: booklet_id });
        dispatch({type: actions.CHANGE_STATE, newState: {booklet: res.data.bookletById}});
    };
}

export function loadBooklets() {
    return async(dispatch) => {
        let res = await GraphQLHelper.query(`query { 
            booklets {
                id
                berufId
                title
                details
                tags
            }
       }`);
       dispatch({type: actions.CHANGE_STATE, newState: { booklets: res.data.booklets, bookletsFetched: true } });
    };
}


export function updateBooklet(booklet_id, data) {
    return async(dispatch) => {
        //check if any key is defined
        if(!Object.values(data).some(e => !!e)) {
            //nothing to do
            return;
        }
        let res = await GraphQLHelper.query(`mutation($bid: ID!, $title: String, $details: String, $berufId: Int, $languages: [ID], $testHeftId: String, $testHeftName: String, $tags: [String!]) {
            updateBooklet(id: $bid, title: $title, languages: $languages, details: $details, berufId: $berufId, testHeftId: $testHeftId, testHeftName: $testHeftName, tags: $tags) {
                id
            }
        }`, {
            bid: booklet_id,
            ...data
        });
        if(res.data.updateBooklet) {
            //reload the booklet
            dispatch(getBooklet(res.data.updateBooklet.id));
        }
    };
}