import { html, css } from "lit-element";
import StoreElement from "../../components/StoreElement";

class VerbisInput extends StoreElement {
    static get properties() {
        return {
            inputPlus: { type: Number }
        };
    }

    static get styles() {
        return css`
            input[type="text"] {
                font-family: Arial;
                font-size: 14px;
                width: 200px;
                height: 30px;
                padding: 1px;
            }
            .button{
                background:#53a8c7;
                color: black;
                font-family: Arial;
                font-size: 20px;
                height: 35px;
                width: 35px;	
                border: 0.5px solid black;
            }
        `;
    }

    constructor() {
        super();
        this.requestData();
    }

    connectedCallback() {
        super.connectedCallback();
        this.inputPlus = 0;
        this.inputs = [];
        this.formData = [];
    }

    storeChanged() { }

    async requestData() { }

    async commit() {
        this.formData = [];
        for (let i of this.shadowRoot.querySelectorAll("input[type=text]")) {
            this.formData.push(i.value);
        }
    }

    get verbis() {
        if (this.formData.length > 0){
            return this.formData.join(", ");
        } else {
            return null;
        }
    }

    handelPlus() {
        this.inputPlus = this.inputPlus + 1;
    }

    handelMinus() {
        if (this.inputPlus <= 0)
            return;
        this.inputPlus = this.inputPlus - 1;
    }

    reset() {
        for (let i of this.shadowRoot.querySelectorAll("input[type=text]")) {
            i.value = "";
        }
        this.inputPlus = 0;
        this.formData = [];
    }

    getInputs() {
        this.inputs = [html`<input @change=${this.commit} type="text" id="verbis_0" name="verbis_0">`];
        for (let i = 0; i < this.inputPlus; i++) {
            this.inputs.push(html`<input @change=${this.commit} type="text" id="verbis_${i + 1}" name="verbis_${i + 1}">`);
        }
        return this.inputs;
    }

    render() {
        return html`
            ${this.getInputs()}
            <input @click=${this.handelPlus} id="plus" type="button" value="+" class="button">
            <input @click=${this.handelMinus} id="minus" type="button" value="-" class="button">
        `;
    }
}


window.customElements.define("verbis-input", VerbisInput);
