import GraphQLHelper from "../graphql";
import * as actions from "../actionTypes";

export function getScreenOverview() {
  return async(dispatch) => {
    let res = await GraphQLHelper.query(`{
        screens {
            id
            name
            language
            content
        }
    }`);
    if (res.data.screens) {
      dispatch({
        type: actions.CHANGE_STATE,
        newState: {
          screens: res.data.screens
        }
      });
    }
  };
}
export function getScreenPaginated(first, skip) {
  return async(dispatch) => {
      let res = await GraphQLHelper.query(`query($first: Int, $skip: Int) {
          screens(first: $first, skip: $skip) {
            id
            name
            content
            language
          }
          screenCount
      }`, { first: first, skip: skip });
      if (res.data.screens) {
          dispatch({
              type: actions.CHANGE_STATE,
              newState: {
                  screens: res.data.screens,
                  totalScreenCount: res.data.screenCount
              }
          });
      }
  };
}
export function getScreenById(screen_id) {
  return async(dispatch) => {
    let res = await GraphQLHelper.query(
      `query screenById($id: Int!) {
            screenById(id: $id) {
                id
                name
                content
                language
            }
        }`,
      { id: parseInt(screen_id) });
    if (res.data.screenById) {
      dispatch({
        type: actions.CHANGE_STATE,
        newState: {
          screen: res.data.screenById,
        },
      });
    }
  };
}

export function changeScreenContent(screen_id, content) {
  return async(dispatch) => {
    let res = await GraphQLHelper.query(
      `mutation updateScreen($id: ID!, $content: String) {
        updateScreen(id: $id, content: $content){id, content}}`,
      {
        id: screen_id,
        content: content,
      }
    );
    if (res.data.updateScreen) {
      dispatch({
        type: actions.CHANGE_STATE,
        newState: {
          screen: res.data.updateScreen,
        },
      });
    }
  };
}
