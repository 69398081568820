
import extractFiles from "extract-files/extractFiles.mjs";
import isExtractableFile from "extract-files/isExtractableFile.mjs";

function statusToText(code) {
    switch(code) {
        case 401:
            return "Not logged in";
        case 403:
            return "Insufficient rights";
        default:
            return code.toString();
    }
}

export class GraphQLError extends Error {
    #error = null;
    #status = null;
    #exception = null;
    constructor(error, status, ex = null) {
        super(`GraphQL failed`);
        this.#error = error;
        this.#status = status;
        this.#exception = ex;
    }

    get error() {
        return this.#error;
    }

    get status() {
        return this.#status;
    }

    toString() {
        if(this.#exception) {
            console.log(this.#exception);
            return `${this.message} with exception: ${this.#exception}`;
        }
        return `${this.message} with: ${this.error} (${this.status})`;
    }
}

class GraphQLExecuteError extends GraphQLError {
    #errors = null;
    constructor(errors) {
        super(null, null, null);
        this.#errors = errors.map(e => { return { message: e.message, status: e.extensions?.code}; });
    }

    get error() {
        return this.#errors.map(e => e.message).join(", ");
    }

    get status() {
        return this.#errors.map(e => e.status).join(", ");
    }
}

export default class GraphQLHelper {
    static store = null;
    static async query(query, variables = {}) {
        return await GraphQLHelper.fetch({ query, variables });
    }
    static async upload(mutation, variables) {
        let {token, version} = GraphQLHelper.store.getState();
        //https://www.floriangaechter.com/blog/graphql-file-uploading/
        /*
            variables are structured as follows:
            {
                variableName: FileObject
            }
        */
        const formData = new FormData();
        let {clone, files} = extractFiles(variables, isExtractableFile, "variables");
        formData.append("operations", JSON.stringify({ query: mutation, variables: clone}));
        const map = {};
        let i = 0;
        files.forEach((paths) => {
            map[++i] = paths;
          });
        formData.append("map", JSON.stringify(map));
        i = 0;
        files.forEach((paths, file) => {
            formData.append(++i, file, file.name);
        });
        try {
            let res = await fetch(globalThis.graphql_url, {
                method: "POST",
                headers: {
                    "Authorization": token ? `Bearer ${token}`: "",
                    "ib_version": version
                },
                body: formData
            });
            if(res.status >= 400) {
                console.log("Client error:", statusToText(res.status));
                return new GraphQLError("Failed to execute upload", res.status);
            }
            return await res.json();
        } catch(e) {
            console.log("Failed to execute upload", e);
            return new GraphQLError("Failed to process fetch", 0, e);
        }
    }
    static async fetch(data) {
        let {token, version} = GraphQLHelper.store.getState();
        if (!data.variables)
            data.variables = {};
        try{
            let res = await fetch(globalThis.graphql_url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": token ? "Bearer " + token : "",
                    "ib_version": version
                },
                body: JSON.stringify(data)
            });
            if(res.status >= 400) {
                //extract the error and propagate it
                let data = await res.json();
                let error = new GraphQLExecuteError(data.errors);
                console.log(`Client error: ${statusToText(res.status)} -> ${error.toString()}`);
                return error;
            }
            return await res.json();
        } catch(e) {
            console.log("Failed to execute query", e);
            return new GraphQLError("Failed to process fetch", 0, e);
        }
    }
}

