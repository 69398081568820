import StoreElement from "../components/StoreElement";
import GraphQLHelper from "../service/graphql";

class ItemForm extends StoreElement {
  static get properties() {
    return {};
  }

  constructor() {
    super();
  }

  storeChanged() { }

  commit(e) {
    e.preventDefault();
    const data = new FormData(e.target);
    var object = {};

    for (const pair of data.entries()) {
      console.log(`the form fields: ${pair[0]}, ${pair[1]}`);
      let key = pair[0];
      let value = pair[1];
      let trimed = value.trim();
      if (!trimed || trimed == "none" || trimed == "" || value.length == 0)
        continue;
      if (!Reflect.has(object, key)) {
        object[key] = trimed;
      }
    }
    //add elmenets to form data
    object["reliant"] = this.renderRoot.querySelector("item-selector").reliant;
    object["verbis"] = this.renderRoot.querySelector("verbis-input").verbis;

    let skills = this.renderRoot.querySelector("skills-inputs").skills;
    object["module"] = skills.module;
    object["esco"] = skills.esco;
    object["idesco"] = skills.idesco;

    let job = this.renderRoot.querySelector("job-selector").job;
    object["job"] = job.job;
    object["schwerpunkt"] = job.schwerpunkt;
    object["handlungsfeld"] = job.handlungsfeld;
    object["situation"] = job.situation;

    for (let i of this.renderRoot.querySelectorAll("asset-dialog")){
      if (i.input.value){
        object[i.input.name] = JSON.parse(i.input.value);
      }
    }
    this.createItem(object);
  }

  async createItem(data) {
    let resCreate = await GraphQLHelper.query(
      `mutation createItem($title: String!, $type: ItemType)
            {
              createItem(
                title: $title, 
                type: $type
                )
                { id}}`,
      {
        title: data.item,
        type: data.type
      }
    );
    if (resCreate.error) {
      console.warn(resCreate.error);
      alert(`Fehler beim erstellen der Item\n\nStatus: ${resUpdate.error.status}`);
      return false;
    }
    delete data.item;
    let resUpdate = await GraphQLHelper.query(
      `mutation updateItemRawData($id: ID!, $lang: String!, $data: JSON!)
            {
              updateItemRawData(
                  id: $id,
                  language: $lang, 
                  rawData: $data
              )
            }`,
      {
        id: resCreate.data.createItem.id,
        lang: "de-DE",
        data: data,
      }
    );

    if (resUpdate.error) {
      console.warn(resUpdate.error);
      alert(`Fehler bei der Bearbeitung der Item\n\nStatus: ${resUpdate.error.status}`);
      return false;
    }
    return true;
  }

  createRenderRoot() {
    return this;
  }

  connectedCallback() {
    super.connectedCallback();
    let form = this.querySelector("form");




    //   // Get all form fields
    //   const formFields = form.elements;
  
    //   // Create an array to store the "name" attributes
    //   const fieldNames = [];
  
    //   // Loop through form fields and extract "name" attributes
    //   for (let i = 0; i < formFields.length; i++) {
    //       if (formFields[i].name) {
    //           fieldNames.push(formFields[i].name);
    //       }
    //   }
  

    //   // Function to convert array to CSV format
    //   function convertToCSV(fieldNames) {
    //     return fieldNames.map(item => `"${item}"`).join(",");
    // }

    // // Function to trigger download
    // function downloadCSV(csvContent, filename) {
    //     const blob = new Blob([csvContent], { type: "text/csv" });
    //     const url = URL.createObjectURL(blob);

    //     const a = document.createElement("a");
    //     a.href = url;
    //     a.download = filename;
    //     document.body.appendChild(a);
        
    //     a.click();

    //     document.body.removeChild(a);
    //     URL.revokeObjectURL(url);
    // }

    // document.getElementById("downloadButton").addEventListener("click", function() {
    //     const csvData = convertToCSV(fieldNames);
    //     downloadCSV(csvData, "string_data.csv");
    // });
    //   // Display the collected "name" attributes    
    //   console.log("Field Names:", fieldNames);
  


    form.addEventListener("submit", (e) => {
      e.preventDefault();
      this.commit(e);
      this.reset(form);
    });
  }

  disconnectedCallback() { }

  reset(form) {
    let selector = this.renderRoot.querySelector("item-selector");
    selector.reset();
    let input = this.renderRoot.querySelector("verbis-input");
    input.reset();
    let skills = this.renderRoot.querySelector("skills-inputs");
    skills.reset();
    let job = this.renderRoot.querySelector("job-selector");
    job.reset();
    form.reset();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

}

window.customElements.define("item-form", ItemForm);
