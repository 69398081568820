import StoreElement from "./StoreElement";
import { html } from "lit-element";


class AuthImage extends StoreElement {
    token = null;
    objectUrl = null;

    static get properties() {
        return {
            src: { type: String },
            objectUrl: { type: Object, attribute: false }
        };
    }

    storeChanged() {
        const { token } = this.store.getState();
        this.token = token;
        if(this.token && !this.objectUrl) {
            this.requestData();
        } else if(this.objectUrl) {
            URL.revokeObjectURL(this.objectUrl);
            this.objectUrl = null;
        }
    }

    disconnectedCallback() {
        URL.revokeObjectURL(this.objectUrl);
    }

    async requestData() {
        if(this.objectUrl) {
            URL.revokeObjectURL(this.objectUrl);
        }
        let imgData = await fetch(this.src, { headers: { authorization: `Bearer ${this.token}` } });
        this.objectUrl = URL.createObjectURL(await imgData.blob());
    }

    render() {
        if(!this.token || !this.objectUrl) {
            return;
        }
        return html`<img .src=${this.objectUrl}>`;
    }
}

window.customElements.define("auth-image", AuthImage);
