import { BaseComponent, h, html, PluginPosition, Grid } from "gridjs/dist/gridjs.modern.js";


export {h, html, Grid};

class PgLimit extends BaseComponent {
    render() {
        let limit = this.config.pagination.limit;
        let on_change = (e) => {
            let _val = e.target.value;
            if (!isNaN(_val)) {
                let _conf = { pagination: { limit: parseInt(_val) } };
                let dataGrid = this.config.instance;
                dataGrid.updateConfig(_conf);
                let _tmp = dataGrid.config.plugin;
                dataGrid.forceRender();
            }
        }
        on_change.bind(this);

        return h('select', {
            className: "form-control",
            style: { width: "auto", display: "inline", marginLeft: "10px", height: "calc(1.66em + .75rem + 2px)" },
            onChange: on_change
        }, [
            h("option", { value: "-", selected: "selected" }, limit + " Items / Seite"),
            h("option", { value: 10 }, "10"),
            h("option", { value: 25 }, "25"),
            h("option", { value: 50 }, "50"),
            h("option", { value: 100 }, "100")
        ]);
    }
}

export const PgLimitPlugin = {
    id: 'pgLimit',
    component: PgLimit,
    position: PluginPosition.Header,
};

export const deDE = {
    search: {
        placeholder: 'Suche...',
    },
    sort: {
        sortAsc: 'Spalte aufsteigend sortieren',
        sortDesc: 'Spalte absteigend sortieren descending',
    },
    pagination: {
        previous: 'Vorherige',
        next: 'Nächste',
        navigate: (page, pages) => `Seite ${page} von ${pages}`,
        page: (page) => `Seite ${page}`,
        showing: 'Zeige',
        of: 'von',
        to: 'bis',
        results: 'Ergebnisse',
    },
    loading: 'Laden...',
    noRecordsFound: 'Keine passenden Einträge gefunden',
    error: 'Es ist ein Fehler aufgetreten',
};