import { html } from "lit-element";
import GraphQLHelper from "../service/graphql";
import StoreElement from "../components/StoreElement";

export default class BookletCreate extends StoreElement {
    static get properties() {
        return {
            user: { type: Object },
            titles: { type: Object },
            berufIds: { type: Object },
            testHeftNames: { type: Object },
            testHeftIds: { type: Object }
        };
    }

    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();
        this.data = null;
        this.job = null;
        this.titles = { selected: null, options: [] };
        this.berufIds = { selected: null, options: [] };
        this.testHeftNames = { selected: null, options: [] };
        this.testHeftIds = { selected: null, options: [] };
        this.handlungsfelder = { selected: null, options: [] };
        this.editable = false;
        this.getJobs();
    }

    createRenderRoot() {
        return this;
    }

    async fetchBookletsData(url) {
        const res = await fetch(url);
        if (res.error) {
            console.log(res.error);
            return [];
        } else {
            return await res.json();
        }
    }

    async getJobs() {
        this.data = await this.fetchBookletsData("/lib/bst.json");
        let jTmp = { selected: null, options: [] };
        let iTmp = { selected: null, options: [] };

        for (let d of this.data) {
            jTmp.options.push({ value: d.id, text: d.name });
            iTmp.options.push({ value: d.id, text: d.berufId });
        }
        this.titles = jTmp;
        this.berufIds = iTmp;
    }

    selectJob(e) {
        this.titles.selected = e.target.value;
        this.job = this.data.find(j => j.name == e.target.value);
        if (!this.job)
            return;
        this.berufIds.selected = this.job.berufId;
        this.querySelector("#berufIdInput").value = this.job.berufId;
        let sNameTmp = { selected: null, options: [] };
        let sIdTmp = { selected: null, options: [] };

        for (let s of this.job.schwerpunkte) {
            sNameTmp.options.push({ value: s.id, text: s.name ?? "" });
            sIdTmp.options.push({ value: s.id, text: s.id ?? "" });
        }
        this.testHeftNames = sNameTmp;
        this.testHeftIds = sIdTmp;
        this.requestUpdate();
    }

    selectBerufId(e) {
        if (!isNaN(e.target.value)) {
            this.job = this.data[e.target.value - 1];
            this.berufIds.selected = e.target.value;
        }
        if (!this.job)
            return;
        this.titles.selected = this.job.name;
        let sNameTmp = { selected: null, options: [] };
        let sIdTmp = { selected: null, options: [] };
        for (let s of this.job.schwerpunkte) {
            sNameTmp.options.push({ value: s.id, text: s.name ?? "" });
            sIdTmp.options.push({ value: s.id, text: s.id ?? "" });
        }
        this.testHeftNames = sNameTmp;
        this.testHeftIds = sIdTmp;
        this.requestUpdate();
    }

    selectTestHeftId(e) {
        if (!isNaN(e.target.value)) {
            let testHeft = this.job?.schwerpunkte.find(s => s.id == e.target.value);
            this.testHeftIds.selected = e.target.value;
            this.testHeftNames.selected = testHeft.name ?? "";

            let hTmp = { selected: null, options: [] };
            for (let h of testHeft.handlungsfelder) {
                hTmp.options.push({ value: h.name, text: h.name });
            }
            this.handlungsfelder = hTmp;
        }
        this.requestUpdate();
    }

    storeChanged() {
        let state = this.store.getState();
        this.user = state.user;
        this.editable = state.user.rights.write && state.version === "master";
        this.requestUpdate();
    }

    commit(e, i) {
        if (i == 1) {
            this.createCompetence();
        } else if (i == 2) {
            this.createBooklet();
        }
        return false;
    }
    async createBooklet() {
        let res = await GraphQLHelper.query(
            `mutation createBooklet($title: String!, $berufId: Int, $testHeftId: ID, $testHeftName: String, $languages: [ID]){
                createBooklet(title: $title, berufId: $berufId, testHeftId: $testHeftId, testHeftName: $testHeftName, languages: $languages){ id }}`,
            {
                title: this.titles.selected,
                berufId: this.berufIds.selected,
                testHeftId: this.testHeftIds.selected,
                testHeftName: this.testHeftNames.selected,
                languages: ["de-DE"]
            }
        );
        if (res.error) {
            console.warn(res.error);
            alert(`Fehler beim erstellen des Booklets\n\nStatus: ${res.error.status}`);
            return false;
        }
        return true;
    }

    //Mutation for adding competences to booklet, should be moved to booklet.edit...
    async addCompetencesToBooklet(id) {
        let competences = this.job?.schwerpunkte.find(s => s.id == id).handlungsfelder;
        let comIds = [];
        for (let h of competences) {
            comIds.push(h.id);
        }
        let res = await GraphQLHelper.query(
            `mutation addCompetenceToBooklet($id: ID!, $competenceId: ID!){
                addCompetenceToBooklet(id: $id, competenceId: $competenceId)}`,
            {
                id: this.job.id,
                competenceIds: comIds
            }
        );
        if (res.error) {
            console.warn(res.error);
            alert(`Fehler beim hinzufügen der Kompetenzen\n\nStatus: ${res.error.status}`);
            return false;
        }
        return true;
    }

    renderCreateBooklet() {
        return html`
        <div class="container flex-start mt-md-5">
            <div class="row">
                <h3>Booklet erstellen: </h3>
            </div>
            <div class="row">
                <label class="col-2" for="titleInput">Title</label>
                <div class="col-6">
                    <input id="titleInput" name="titleInput" list=titles  @change=${this.selectJob} required ?readonly=${this.editable} class="col-sm-9">
                    <datalist id="titles">
                        <select name="titleInput">
                            ${this.titles.options.map(e => html`<option value=${e.text} ?selected=${e.text == this.titles.selected}>
                            </option>`)}
                        </select>
                    </datalist>
                </div> 
            </div>                   
            <div class="row">
                <label class="col-2" for="berufIdInput">BerufId</label>
                <div class="col-6">
                    <input id="berufIdInput" name="berufIdInput" list=berufIds  @change=${this.selectBerufId} autocomplete="off" ?readonly=${this.editable} class="col-sm-9">
                    <datalist id="berufIds">
                        <select name="berufIdInput">
                            ${this.berufIds.options.map(e => html`<option value=${e.text} ?selected=${e.text == this.berufIds.selected}>
                            </option>`)}
                        </select>
                    </datalist>
                </div>
            </div>
            <div class="row">
                <label class="col-2" for="schwerpunktInput">SchwerpunktName</label>
                <div class="col-6">
                    <input id="schwerpunktInput" name="schwerpunktInput" list=schwerpunkts  @change="${this.selectTestHeftName}" autocomplete="off" ?readonly=${this.editable} class="col-sm-9">
                    <datalist id="schwerpunkts">
                        <select name="schwerpunktInput">
                            ${this.testHeftNames.options.map(e => html`<option value=${e.text} ?selected=${e.text == this.testHeftNames.selected}>
                            </option>`)}
                        </select>
                    </datalist>
                </div>
            </div>
            <div class="row">
                <label class="col-2" for="schwerpunktIdInput">SchwerpunktId</label>
                <div class="col-6">
                    <input id="schwerpunktIdInput" name="schwerpunktIdInput" list=schwerpunktIds  @change="${this.selectTestHeftId}" autocomplete="off" ?readonly=${this.editable} class="col-sm-9">
                    <datalist id="schwerpunktIds">
                        <select name="schwerpunktIdInput">
                            ${this.testHeftIds.options.map(e => html`<option value=${e.text} ?selected=${e.text == this.testHeftIds.selected}>
                            </option>`)}
                        </select>
                    </datalist>
                </div>
                <button type="button" value="createBooklet" class="btn btn-outline-success" @click="${(e) => { this.commit(e, 2); }}">Booklet erstellen</button>
            </div>
        </div>`;
    }
    render() {
        if (!this.user) {
            return html`Nicht eingeloggt -> Bitte einloggen!`;
        }
        return html`
        <link rel="stylesheet" href="/css/bootstrap.min.css">
        <div class="container flex-start" style="position: relative; display: inline-block;">
            ${this.renderCreateBooklet()} 
        </div>`;
    }
}

window.customElements.define("booklet-create", BookletCreate);
