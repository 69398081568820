export const LOGIN = "login";
export const LOGOUT = "logout";
export const MOVE_ITEM_IN_COMPETENCE = "moveItemInCompetence";
export const ADD_ITEM_TO_COMPETENCE = "addItemToCompetence";
export const REMOVE_ITEM_FROM_COMPETENCE = "removeItemfromCompetence";
export const ADD_SCREEN_TO_COMPETENCE = "addScreenToCompetence";
export const REMOVE_SCREEN_FROM_COMPETENCE = "removeScreenfromCompetence";
export const CHANGE_VERSION = "changeVersion";
export const CHANGE_STATE = "changeState";
export const ADD_SECTION = "addSection";
export const REMOVE_SECTION = "removeSection";