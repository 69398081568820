import { html, svg } from "lit-element";
import StoreElement from "../components/StoreElement";
import GraphQLHelper from "../service/graphql";

export class ImageMap extends StoreElement {
    static get properties() {
        return {
            data: { attribute: false },
            file: { type: String, attribute: false }
        };
    }

    get hasImage() {
        return !!(this.data?.rawData?.ImageResourceName || this.data?.rawData?.image);
    }

    get imageUrl() {
        if (!this.hasImage){
            return null;
        }
        let name;
        let url;
        if (this.data.rawData.ImageResourceName){
            name = this.data.rawData.ImageResourceName.split("/").reverse()[0];
            let res = this.data.assets.find(e => e.assetName.endsWith(name));
            url = res?.file.url;
        //apollo items    
        } else if (this.data.rawData.image) {
            name = this.data.rawData.image.name; 
            this.imageId = this.data.rawData.image.id; 
            url = this.file;
        }

        if(!url)
            return null;
        let u = new URL(url);
        let graphql = new URL(globalThis.public_assets_url);
        u.hostname = graphql.hostname;
        return u.toString();
    }

    constructor() {
        super();
        this.imageHeight = 0;
        this.imageWidth = 0;
        this.imageObjectUrl = null;
        this.drawStyle = "stroke: black; stroke-width: 3; fill: grey; fill-opacity: 0.3";
        this.areas = [];
        this.scoring = [];
        this.file = null;
        this.imageId = null;
    }

    connectedCallback() {
        super.connectedCallback();
        this.requestData();
    }

    async requestData() {
        if (!this.imageId)
            return;
        let res = await GraphQLHelper.query(`query file($id: ID!) { file(id: $id) {url} } `, { id: this.imageId });
        if (res.error) {
            console.log(res.error);
        } else {
            this.file = res.data.file.url;
            this.changeState({file : this.file});
        }
    }

    firstUpdated() {
        if (!this.hasImage) {
            return;
        }
        this.parseData();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        URL.revokeObjectURL(this.imageObjectUrl);
    }

    async storeChanged() {
        const {token} = this.store.getState();
        //load image to get dimensions
        
        let img = new Image();
        let imgData = await fetch(this.imageUrl, { headers: { authorization: `Bearer ${token}` } });
        this.imageObjectUrl = URL.createObjectURL(await imgData.blob());
        await new Promise((res) => {
            img.onload = () => {
                this.imageWidth = img.width;
                this.imageHeight = img.height;
                this.requestUpdate();
                res();
            };
            img.src = this.imageObjectUrl;
        });
    }

    idx2Alpha(idx) {
        return String.fromCharCode("A".charCodeAt(0) + idx - 1);
    }

    getText(x, y, id) {
        return svg`<text dominant-baseline="middle" style="font-size: 2em; text-anchor: middle" x=${x} y=${y}>${this.idx2Alpha(id)}</text>`;
    }

    createEllipse(form) {
        let params = form.coords.split(";");
        return svg`
            <ellipse style=${this.drawStyle} cx=${params[0]} cy=${params[1]} rx=${params[2]} ry=${params[3]} />
            ${this.getText(params[0], params[1], form.id)}`;
    }

    createCircle(form) {
        let params = form.coords.split(";");
        return svg`
            <circle style=${this.drawStyle} cx=${params[0]} cy=${params[1]} r=${params[2]}  />
            ${this.getText(params[0], params[1], form.id)}`;
    }

    createPolygon(form) {
        let params = form.coords.replaceAll(";", ",");
        let coords = params.split(",");
        let center = {
            l: Infinity,
            r: 0,
            t: Infinity,
            b: 0
        };
        for(let i=0; i < coords.length; i+=2) {
            let x = parseInt(coords[i]);
            let y = parseInt(coords[i+1]);
            center.l = Math.min(center.l, x);
            center.r = Math.max(center.r, x); 
            center.t = Math.min(center.t, y);
            center.b = Math.max(center.b, y);
        }
        console.log(center);
        return svg`
            <polygon style=${this.drawStyle} points=${params} />
            ${this.getText(center.l + (center.r - center.l) * 0.5, center.t + (center.b - center.t) * 0.5, form.id)}`;
    }

    createRectangle(form) {
        let params = form.coords.split(";");
        //parameters are absolute positions not relative sizes
        let x = parseInt(params[0]);
        let y = parseInt(params[1]);
        let width = parseInt(params[2]) - x;
        let height = parseInt(params[3]) - y;
        return svg`
            <rect x=${x} y=${y} width=${width} height=${height} style=${this.drawStyle} />
            ${this.getText(x + width * 0.5, y + height * 0.5, form.id)}`;
    }

    parseData() {
        this.areas = [];
        this.scoring = [];
        let forms = [];
        for (let [k, v] of Object.entries(this.data.rawData)) {
            if (k.startsWith("ImageMapForm_")) {
                let id = k.split("_")[1];
                let coords = this.data.rawData[`ImageMapFormDetails_${id}`];
                forms.push({
                    type: v,
                    id: parseInt(id),
                    coords
                });
            }
            if (k.startsWith("form")) {
                let id = k.split("form")[1];
                let coords = this.data.rawData[`coordinates${id}`];
                forms.push({
                    type: v,
                    id: parseInt(id),
                    coords
                });
            }
            if (k.startsWith("ScoringOption")) {
                let id = k.split("_")[1];
                this.scoring.push({
                    id: parseInt(id),
                    scores: v.split("-").map(e => e.trim()),
                });
            }
            if (k.startsWith("m_imagemap_option")) {
                let id = k.split("m_imagemap_option")[1].substring(1, 0);
                let score = this.scoring.find(o => o.id == id);
                if (score && score.id == id){
                    score.valueOf(id).scores.push(v.trim());
                } else {
                    this.scoring.push({
                        id: parseInt(id),
                        scores: [v.trim()]
                    });
                }
            }
        }
        for (let e of forms) {
            switch (e.type.toUpperCase()) {
                case "ELLIPSE":
                    this.areas.push(this.createEllipse(e));
                    break;
                case "RECTANGLE":
                    this.areas.push(this.createRectangle(e));
                    break;
                case "CIRCLE":
                    this.areas.push(this.createCircle(e));
                    break;
                case "POLYGON":
                    this.areas.push(this.createPolygon(e));
                    break;
            }
        }
    }

    renderImage() {
        return svg`
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 ${this.imageWidth} ${this.imageHeight}" >
            <image width="${this.imageWidth}" height="${this.imageHeight}" href="${this.imageObjectUrl}"></image>
            ${this.areas}
        </svg>`;
    }

    renderTable() {
        if (this.scoring.length === 0)
            return;
        let header = this.scoring[0].scores.map((e, idx) => {
            return html`<th>${this.idx2Alpha(idx + 1)}</th>`;
        });
        let rows = [];
        for (let s of this.scoring) {
            rows.push(html`<tr><td>Scoring Option ${s.id}</td>${s.scores.map(e => html`<td>${e}</td>`)}</tr>`);
        }
        return html`
        <style>
            table, th, td {
                border-collapse: collapse;
                border: 1px solid black;
            }
            td,th {
                padding: 1em;
            }
        </style>
        <table>
            <tr>
                <th></th>
                ${header}
            </tr>
            ${rows}
        </table>`;
    }
    
    render() {
        if (!this.hasImage) {
            return html`Keine Vorschau`;
        }
        return [
            this.renderImage(),
            this.renderTable()
        ];
    }
}

window.customElements.define("image-map", ImageMap);