import { html } from "lit-element";
import StoreElement from "../components/StoreElement";

function timer(ms) {
    return new Promise(res => {
        setTimeout(res, ms);
    });
}

export class DebugTool extends StoreElement {
    DEFAULT_TEXT = "State ändern";
    CHANGED_TEXT = "State geändert";
    static get properties() {
        return {
            name: { type: String }
        };
    }

    constructor() {
        super();
        this.name = "State ändern";
    }

    createRenderRoot() {
        return this;
    }

    storeChanged() {
        this.state = JSON.stringify(this.store.getState(), null, 2);
        this.name = this.CHANGED_TEXT;
        timer(5000).then(() => this.name = this.DEFAULT_TEXT);
    }

    injectState(e) {
        e.preventDefault();
        try {
            let newState = JSON.parse(e.target.elements.state.value);
            this.changeState(newState);
            $("#modal-debug").modal("hide");
        } catch {
            console.error("Syntax error");
        }
    }

    render() {
        return html`
        <div id="modal-debug" class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">State Editor</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form id="debugform" @submit=${this.injectState}>
                        <div class="modal-body">
                            <textarea class="form-control" name="state" rows="20" .value=${this.state} autocomplete="off" spellcheck="false"></textarea>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Ändern</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="fixed-bottom pr-2 pb-2" style="left: auto;">
            <button id="debugModalButton" type="button" class="btn btn-danger btn-sm float-right" data-toggle="modal" data-target="#modal-debug">${this.name}</button>
        </div>`;
    }
}

window.customElements.define("debug-tool", DebugTool);
