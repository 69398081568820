import { html, LitElement } from "lit-element";
import GraphQLHelper from "../service/graphql";

class DataInput extends LitElement {
    static get properties() {
        return {
            key: { type: String, attribute: true },
            value: { type: Object },
            editable: { type: Boolean },
            editFields: { type: Object }
        };
    }

    constructor() {
        super();
        this.editable = false;
        this.editFields = {};
    }

    connectedCallback() {
        super.connectedCallback();
        (async() => {
            let field_settings_res = await GraphQLHelper.query(`query {
                settings(key: "forms")
            }`);
            this.editFields = field_settings_res.data.settings?.editFields;
        })();
    }

    createRenderRoot() {
        return this;
    }

    onChangeData(e) {
        let value = e.target.value;
        if(e.target.type === "number") {
            value = Number(value);
        }
        let evt = new CustomEvent("onChangeData", {
            detail: {
                target: e.target,
                name: e.target.name,
                value
            }
        });
        this.dispatchEvent(evt);
    }

    //onInput and checkbox-onChange are not triggered
    renderAsset() {
        return html`<asset-dialog><input type="text" @change=${this.onChangeData} @input=${this.onChangeData} @keyup=${this.onChangeData} name="${this.key}" .value=${this.value} class="form-control" ?readonly=${!this.editable} /></asset-dialog>`;
    }

    renderCheckbox() {
        return html`<input type="checkbox" @change=${this.onChangeData} name="${this.key}" ?checked=${this.value === "on"} />`;
    }

    renderMultiline() {
        return html`<textarea @keyup=${this.onChangeData} name="${this.key}" class="form-control" .value=${this.value} ?readonly=${!this.editable}></textarea>`;
    }

    renderNumber() {
        return html`<input type="number" @keyup=${this.onChangeData} name="${this.key}" .value=${this.value} class="form-control" ?readonly=${!this.editable} />`;
    }

    renderText() {
        return html`<input type="text" @keyup=${this.onChangeData} @input=${this.onChangeData} name="${this.key}" .value=${this.value} class="form-control" ?readonly=${!this.editable} />`;
    }

    render() {
        if(this.editFields) {
            for (let [match, type] of Object.entries(this.editFields)) {
                if (new RegExp(match).test(this.key)) {
                    switch (type) {
                        case "asset":
                            return this.renderAsset();
                        case "checkbox":
                            return this.renderCheckbox();
                        case "multiline":
                            return this.renderMultiline();
                        case "number":
                            return this.renderNumber();
                        case "text":
                        default:
                            return this.renderText();
                    }
                }
            }
        }
        //default: text
        return this.renderText();
    }
}

customElements.define("data-input", DataInput);