import {AuthHandler} from "@myskills/auth-client";
import store from "./store";
import * as actions from "./actionTypes";

console.log("Patch fetch to use AuthHandler instead");

const handler = new AuthHandler(globalThis.auth_url, null, null);

store.subscribe(() => {
    const {token, refreshToken} = store.getState();
    console.log("Setting token:", !!token);
    handler.token = token;
    console.log("Setting refreshToken", !!refreshToken);
    handler.refreshToken = refreshToken;
});

//listen to updates to the tokens
handler.subscribe((token, refreshToken) => {
    if(!token || !refreshToken) {
        console.log("Logging out");
        store.dispatch({type: actions.LOGOUT});
        return;
    }
    console.log("Storing login", token, refreshToken);
    store.dispatch({type: actions.LOGIN, token, refreshToken});
});

//override
globalThis.fetch = handler.fetch.bind(handler);