import { html, css } from "lit-element";
import StoreElement from "../components/StoreElement";
import * as actions from "../service/actionTypes";

export class CompetenceItem extends StoreElement {

    static get styles() {
        return css`
        :host {
            display: block;
            width: 100%;
        }
      `;
    }

    static get properties() {
        return {
            id: { type: String },
            title: { type: String },
            blocked: { type: Boolean },
            wrong: { attribute: false },
            right: { attribute: false },
            errors: { type: Array, attribute: false },
            index: { type: Number },
            editable: {type: Boolean}
        };
    }

    get competence() {
        return this.closest("competence-container").id;
    }

    constructor(id, name, blocked) {
        super();
        this.title = typeof title == "string" ? title : "Item";
        this.id = typeof id == "string" ? name : "Item";
        this.blocked = typeof blocked == "boolean" ? blocked : false;
        this.correct = null;
        this.wrong = null;
        this.errors = [];
        this.class = "";
        this.index = -1;
        this.editable = false;
    }

    storeChanged() {
        const state = this.store.getState();
        this.editable = state.user.rights.write && state.version === "master";
    }

    firstUpdated() {
        const attachListeners = this.getAttribute("draggable") == "true";
        if(!attachListeners)
            return;
        this.addEventListener("dragstart", (ev) => this.dragStart(ev));
        this.addEventListener("dragover", (ev) => this.dragOver(ev));
        this.addEventListener("dragleave", (ev) => this.dragLeave(ev));
        this.addEventListener("dragend", this.dragEnd.bind(this));
    }

    dragStart(event) {
        if(this.store.getState().dragTarget !== null) {
            console.log("Not dragging -> we already have a drag");
            return false;
        }
        this.changeState({ dragTarget: { element: this.id, competence: this.competence }});
        event.dataTransfer.setData("text/plain", this.id);
        event.dataTransfer.effectAllowed = "move";
    }

    dragOver(event) {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
        this.firstElementChild.classList.add("border-danger");
        this.store.dispatch({ type: actions.MOVE_ITEM_IN_COMPETENCE, before: {element: this.id, competence: this.competence }});
    }

    dragLeave() {
        this.firstElementChild.classList.remove("border-danger");
    }

    dragEnd() {
        //cleanup
        console.log("Drag end");
        this.firstElementChild.classList.remove("border-danger");
        this.changeState({dragTarget: null});
    }

    remove() {
        this.store.dispatch({ type: actions.REMOVE_ITEM_FROM_COMPETENCE, removedItem: {element: this.id, competence: this.competence }});
    }

    createRenderRoot() {
        return this;
    }
    //TODO: add ?hidden=${!this.editable}
    renderDeleteButton() {
        return html`            
            <div class="float-right mr-2 mt-1">
                <a @click=${this.remove} class="btn btn-sm btn-outline-secondary" data-toggle="tooltip" title="Löschen"><i class="mdi mdi-delete"></i></a>
            </div>
            `;
    }  

    renderItem(id, name, css) {
        return html`          
            <div id="${id}" class="${css} item p-3">
                <div class="row justify-content-between">
                    <div class="title col">
                        ${this.editable ? this.renderDeleteButton() : ""}
                        ${!this.blocked ? html`<a href="/item/${id}">${name}</a>` : id}
                        ${this.errors.map(error => html`
                            <br /><span class="text-${error.type}"><i class="mdi mdi-alert"></i>${error.msg}</span>                             
                        `)}                            
                    </div>
                </div>
            </div>           
        `;
    }

    render() {
        let border = this.index === 0 ? "" : "border-top-0";
        if (!!this.wrong && !!this.correct) {
            if(this.wrong.id === this.correct.id) {
                return;
            }
            return html`
              <div class="row border ${border}">
                    ${this.renderItem(this.id, this.title, "col-12", false)}            
                    ${this.renderItem(this.correct.id, this.correct.title, "col-6 item-correct", true)}
                    ${this.renderItem(this.wrong.id, this.wrong.title, "col-6 item-wrong", true)}
              </div>`;
        }
        return html`
        <div class="row border ${border}">
            ${this.renderItem(this.id, this.title, "col-12", false)}            
        </div>`;
    }
}

window.customElements.define("competence-item", CompetenceItem);