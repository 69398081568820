import GraphQLHelper from "../graphql";
import * as actions from "../actionTypes";
import {getBooklet} from "./booklet";

export function getCompetences() {
    return async(dispatch) => {
        let res = await GraphQLHelper.query(`query {
            competences {
                id
                title
                label
                abbreviation
            }
        }`);
        dispatch({
            type: actions.CHANGE_STATE,
            newState: {
                competences: {
                    data: res.data.competences,
                    fetched: true
                }
            }
        });
    };
}

export function addCompetenceToCurrentBooklet(competence_id) {
    return async(dispatch, getState) => {
        let booklet_id = getState().booklet.id;
        if(!booklet_id)
            return;
        let res = await GraphQLHelper.query(`mutation($bid: ID!, $cid: ID!) {
            addCompetenceToBooklet(id: $bid, competenceId: $cid)
        }`, {
            bid: getState().booklet.id,
            cid: competence_id
        });
        if(res.data.addCompetenceToBooklet) {
            //reload the booklet
            dispatch(getBooklet(booklet_id));
        }
    };
}

export function removeCompetenceFromCurrentBooklet(competence_id) {
    return async(dispatch, getState) => {
        let booklet_id = getState().booklet.id;
        if(!booklet_id)
            return;
        let res = await GraphQLHelper.query(`mutation($bid: ID!, $cid: ID!) {
            removeCompetenceFromBooklet(id: $bid, competenceId: $cid)
        }`, {
            bid: getState().booklet.id,
            cid: competence_id
        });
        if(res.data.removeCompetenceFromBooklet) {
            //reload the booklet
            dispatch(getBooklet(booklet_id));
        }
    };
}

export function setCompetencesInCurrentBooklet(competence_ids) {
    return async(dispatch, getState) => {
        let booklet_id = getState().booklet.id;
        if(!booklet_id)
            return;
        let res = await GraphQLHelper.query(`mutation($bid: ID!, $cid: [ID!]) {
            setCompetencesInBooklet(id: $bid, competenceIds: $cid)
        }`, {
            bid: getState().booklet.id,
            cid: competence_ids
        });
        if(res.data.setCompetencesInBooklet) {
            //reload the booklet
            dispatch(getBooklet(booklet_id));
        }
    };  
}

export function createSection({name, label, abbreviation}) {
    return async(dispatch) => {
        let res = await GraphQLHelper.query(`mutation($title: String!, $label: String, $abbreviation: String) {
            createCompetence(title: $title, label: $label, abbreviation: $abbreviation) {
                label
                abbreviation
                title
                id
            }
        }`, {
            title: name,
            label,
            abbreviation
        });
        if(res.data.createCompetence) {
            dispatch({type: actions.ADD_SECTION, competence: res.data.createCompetence});
        }
    };
}