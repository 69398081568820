import "@ui5/webcomponents/dist/ComboBox";
import "@ui5/webcomponents/dist/MultiInput";
import "./service/auth";
import "./service/graphql";
import "./service/store";
import "./components/nega-editable-text";
import "./components/data-input";
import "./components/login-handler";
import "./components/version-modal";
import "./components/navbar";
import "./components/auth-image";
import "./assets";
import "./competence";
import "./booklets";
import "./items";
import "./screens";
import "./debug";
import "./impressum";

/* eslint-disable no-undef */
if(globalThis.DEBUG_MODE === true) {
    console.log("Debug mode enabled");
    document.addEventListener("DOMContentLoaded", () => {
        let dt = document.createElement("debug-tool");
        document.body.append(dt);
    });
}