import StoreElement from "../components/StoreElement";
import { html as GridHTML, Grid, PgLimitPlugin, deDE } from "../components/grid";
import { escape } from "lodash-es";


export class ScreenList extends StoreElement {

    static get properties() {
        return {
            screens: { type: Array }
        };
    }

    constructor() {
        super();
        this.grid = null;
        this.screens = [];
    }

    connectedCallback() {
        super.connectedCallback();
        this.store.dispatch(this.store.thunks.getScreenOverview());
        this.render();
    }

    createRenderRoot() {
        return this;
    }

    storeChanged() {
        this.screens = this.store.getState().screens;
        this.render();
    }

    render() {
        const conf = {
            columns: [
                {
                    id: "id",
                    hidden: true
                },
                {
                    name: "Name",
                    id: "name",
                    formatter: (cell, row) => {
                        return GridHTML(`<a href="/screen/${row.cells[0].data}"><b>${cell}</a>`);
                    }
                },
                {
                    name: "Sprache",
                    id: "language",
                    formatter: (cell) => {
                        return GridHTML(`<div style="padding: 0px;">${cell}</div>`);
                    }
                },
                {
                    name: "Inhalt",
                    id: "content",
                    sort: false,
                    formatter: (cell, row) => {
                        let cell_text = escape(cell).replaceAll("\n", "<br/>");
                        return GridHTML(`<nega-editable-text data-id="${row.cells[0].data}">${cell_text}</nega-editable-text>`);
                    }
                }
            ],
            data: this.screens,
            pagination: {
                limit: 50
            },
            search: true,
            sort: true,
            fixedHeader: true,
            language: deDE,
            plugins: [PgLimitPlugin]
        };
        if (!this.grid) {
            this.grid = new Grid(conf);
            this.replaceChildren();
            this.grid.render(this);
        } else {
            this.grid.updateConfig(conf);
            this.replaceChildren();
            this.grid.forceRender();
        }
    }

}

window.customElements.define("screen-list", ScreenList);