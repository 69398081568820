
import GraphQLHelper from "../graphql";
import * as actions from "../actionTypes";

export function loadLanguages() {
 return async(dispatch) => {
    let res = await GraphQLHelper.query(`{
        __type(name: "Localization") {
            name
            fields {
              name
            }
          }
    }`);
    if (res.data.__type.fields) {
        let languages = [];
        for (let value of res.data.__type.fields) {
            languages.push (value.name.replace("_", "-"));
          }
      dispatch({
        type: actions.CHANGE_STATE,
        newState: {
          languages: languages
        }
      });
    }
  };
}


