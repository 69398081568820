import StoreElement from "../../components/StoreElement";
import GraphQLHelper from "../../service/graphql";
import { html } from "lit-element";
import cloneDeep from "lodash-es/cloneDeep";

class TextInput extends HTMLElement {
    connectedCallback() {
        let lang = this.getAttribute("lang");
        let id = this.getAttribute("booklet-id");
        this.input = document.createElement("input");
        this.input.type = "checkbox";
        this.input.name = `booklet[${id}]`;
        this.input.value = lang;
        this.input.hidden = true;
        this.input.checked = true;
        this.innerText = lang;
        this.classList.add("btn", "btn-sm", "btn-outline-success");
        this.appendChild(this.input);
        this.addEventListener("click", this.toggle.bind(this));

    }
    get isChecked(){
        return this.input.checked;
    }

    toggle(e) {
        e.stopPropagation();
        this.input.checked = !this.input.checked;
        this.classList.remove("btn-outline-success", "btn-outline-danger");
        if (this.input.checked)
            this.classList.add("btn-outline-success");
        else
            this.classList.add("btn-outline-danger");

        let myEvent = new CustomEvent("toggle", {
            bubbles: true, 
            composed: true });
        this.dispatchEvent(myEvent);
    }
}

window.customElements.define("text-input", TextInput);

class BookletExportList extends StoreElement {
    static get properties() {
        return {
            booklets : { type: Object, attribute: false }
        };
    }

    constructor() {
        super();
        this.booklets = null;
        this.originBooklets = null;
    }

    createRenderRoot() {
        return this;
    }

    async requestData() {
        let res = await GraphQLHelper.query(`query { booklets { id berufId title languages } }`);
        if(res.error) {
            console.log(res.error);
            this.changeState({ exportBooklets: { booklets: [], fetched: true }});
        } else {
            this.changeState({ exportBooklets: { booklets: res.data.booklets, fetched: true} });
        }
    }

    storeChanged() {
        const {exportBooklets} = this.store.getState();
        if(!exportBooklets.fetched) {
            this.requestData();
            return;
        }
        this.booklets = cloneDeep(exportBooklets.booklets);
        if (!this.originBooklets){
            this.originBooklets = cloneDeep(this.booklets);
        }
    }

    updateSelection(id) {
        let booklet = this.booklets.find(e => e.id === id);
        booklet.selected = !booklet.selected;
        this.changeState({ exportBooklets: { booklets: this.booklets, fetched: true } });
    }

    updateLanguageSelection(e, id) {
        let booklet = this.booklets.find(e => e.id === id);
        if (!booklet.selected){
            booklet.selected = true;
        }
        if (!e.target.isChecked){
            booklet.languages = booklet.languages.filter(l => l != e.target.innerText);
        } else {
            if (booklet.languages.includes(e.target.innerText))
                return;
            booklet.languages.push(e.target.innerText); 
        }
        this.changeState({ exportBooklets: { booklets: this.booklets, fetched: true } });
    }

    selectAll(e) {
        for(let b of this.booklets) {
            b.selected = e.target.checked;
        }
        this.changeState({ exportBooklets: { booklets: this.booklets, fetched: true } });
    }

    *renderBooklets() {
        for(let b of this.booklets) {
            let languages = this.originBooklets.find(book => book.id === b.id).languages;
            yield html`
                <tr @click=${(e) => { e.stopPropagation(); this.updateSelection(b.id); } }>
                    <td><input type="checkbox" name="booklet" @click=${(e) => {e.stopPropagation(); this.updateSelection(b.id); }} .checked=${b.selected} /></td>
                    <td>${b.berufId}</td>
                    <td>${b.title}</td>
                    <td>${languages.map(l => html`<text-input booklet-id="${b.id}" lang=${l} @toggle=${(e) => {this.updateSelection(b.id); this.updateLanguageSelection(e, b.id);}}></text-input>`)}</td>
                </tr>
            `;
        }
    }

    render() {
        if(!this.booklets)
            return html`Keine Booklets`;
        return html`
        <table class="col table table-hover">
                <thead>
                    <tr>
                        <th scope="col"></th>
                        <th>Beruf ID</th>
                        <th>Booklet</th>
                        <th>Sprachen</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colspan=4 ><label>Alle Auswählen <input type="checkbox" @click=${this.selectAll} /></label></td>
                    </tr>
                    ${this.renderBooklets()}
                </tbody>
        </table>
        `;
    }
}


window.customElements.define("export-list", BookletExportList);