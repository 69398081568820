import { html } from "lit-element";
import { classMap } from "lit-element/node_modules/lit-html/directives/class-map.js";
import "@ui5/webcomponents/dist/ComboBox";
import { map, cloneDeep } from "lodash-es";
import GraphQLHelper, { GraphQLError } from "../service/graphql";
import StoreElement from "../components/StoreElement";
import * as actions from "../service/actionTypes";

export class CompetenceContainer extends StoreElement {
    static get properties() {
        return {
            id: { type: String },
            alerts: { type: Array, attribute: false },
            expanded: { type: Boolean}
        };
    }

    addAlert(alert) {
        //remove it after timeout
        alert.timer = setTimeout(() => {
            this.alerts = this.alerts.filter(e => e !== alert);
        }, alert.timeout * 1000);
        //clear others with same message
        this.alerts = this.alerts.filter(e => {
            if(e.content === alert.content) {
                clearTimeout(e.timer);
                return false;
            }
            return true;
        });
        //finally add the new one
        this.alerts = [...this.alerts, alert];
    }

    constructor() {
        super();
        this.alerts = [];
        this.competence = null;
        this.booklet = null;
        this._lastSearch = "";
        this._dirtyBit = false;
        this.editable = false;
        this.expanded = false;
    }

    createRenderRoot() {
        return this;
    }

    storeChanged() {
        if (!this.id)
            return;
        const state = this.store.getState();
        this.editable = state.user.rights.write && state.version === "master";
        //get the competence based on the id
        this.competence = cloneDeep(this.findCompetence());
        if (!this.booklet) {
            //only store for the first time, so that we can undo it
            this.booklet = cloneDeep(state.booklet);
        }
        if (this.competence){
            this.competence.allowedTime = this.competence?.allowedTime ?? -1;
        }
        //update
        this.requestUpdate();
    }

    findCompetence() {
        return this.store.getState().booklet?.competences?.find(e => e.id === this.id);
    }

    toggleExpand() {
        let evt = new CustomEvent("toggle", {
            detail: {
                id: this.id,
                expanded: !this.expanded
            }
        });
        this.dispatchEvent(evt);
    }

    undo() {
        let booklet = this.booklet;
        this.booklet = null;
        this.changeState({ booklet });
    }

    async searchItems(event) {
        let search = event.target.filterValue;
        //only search if there are more than 3 characters
        if (search.length < 3 || event.target.loading)
            return;

        if (search.toLowerCase() == this._lastSearch.toLowerCase())
            return;
        this._lastSearch = search;

        while (event.target.firstChild) {
            event.target.removeChild(event.target.firstChild);
        }

        event.target.loading = true;
        let res = await GraphQLHelper.query(`query itemSearch($search: String!) {
            itemSearch(expression: $search) { title id }
        }`, { search });
        if(res instanceof GraphQLError) {
            return;
        }
        //add to items to list
        for (let item of res.data.itemSearch) {
            const element = document.createElement("ui5-cb-item");
            element.setAttribute("text", item.title);
            element.setAttribute("id", item.id);
            event.target.appendChild(element);
        }
        event.target.loading = false;
    }

    async addItem() {
        let cb = this.renderRoot.querySelector("ui5-combobox");
        if (!!cb && cb._lastValue.length > 4) {
            //Get UUID
            let search = cb._lastValue;
            let res1 = await GraphQLHelper.query(`query itemSearch($search: String!) {
                    itemSearch(expression: $search) { id, title }
                }`, { search });
            const item = res1.data.itemSearch.filter(data => {
                return data.title === search ;
                });
            console.log(item);
            //validate id against graphql
            let res = await GraphQLHelper.query(`query validateId($id: ID!) {itemById(id: $id) { id title }}`, { id: item[0].id });
            if (!res.data.itemById.id) {
                this.addAlert({
                    content: `Item ${search} nicht in der Datenbank gefunden`,
                    type: "warning",
                    timeout: 15
                });
                return false;
            }
            this.store.dispatch({ type: actions.ADD_ITEM_TO_COMPETENCE, addedItem: { element: res.data.itemById, competence: this.competence.id } });
        }
    }
    async addScreen() {
        let inp = this.renderRoot.querySelector("input").value;
        if (inp) {
            //If screen exist
            let res = await GraphQLHelper.query(`query screenById($id: Int!) {
                screenById(id: $id) { id, language, name }
                }`, { id: parseInt(inp) });
            if (!res.data) {
                this.addAlert({
                    content: `Screen ${inp} nicht in der Datenbank gefunden`,
                    type: "warning",
                    timeout: 15
                });
                return false;
            }
            this.store.dispatch({ type: actions.ADD_SCREEN_TO_COMPETENCE, addedScreen: { element: res.data.screenById, competence: this.competence.id } });
        }
    }
    async saveAddedScreen(screen) {
        let res = await GraphQLHelper.query
            (`mutation addScreenToCompetence($id: ID!, $screenId: Int!, $language: String!) {addScreenToCompetence(id: $id, screenId: $screenId, language: $language) }`
                , { id: this.id, screenId: parseInt(screen.id), language: screen.language });
        if(res instanceof GraphQLError) {
            this.addAlert({
                content: res.toString,
                type: "danger",
                timeout: 5
            });
            return false;
        }
        return true;
    }

    async saveRemovedScreen(screen) {
        //can't delete screens by id in any language, ids are not always equal 
        let languages = ["ar-arb", "en-US", "de-DE", "tr-TR", "ru-RU", "fa-PRS", "bg-BG", "ro-RO", "pl-PL", "fr-FR", "pt-PT", "es-ES"];
        let res = await GraphQLHelper.query
            (`mutation removeScreensFromCompetence($id: ID!, $screenId: Int!, $languages: [String!]!) {removeScreensFromCompetence(id: $id, screenId: $screenId, languages: $languages) }`
                , { id: this.id, screenId: parseInt(screen.id), languages:  languages});
        if(res instanceof GraphQLError) {
            this.addAlert({
                content: res.toString,
                type: "danger",
                timeout: 5
            });
            return false;
        }
        return true;
    }

    dragEnd() {
        for (let el of this.querySelectorAll("competence-item > div")) {
            el.classList.remove("border-danger");
        }
    }

    async save() {
        let res = await this.saveSequence();
        res = await this.saveScreens() || res;
        this.competence.changed = false;
        if(res) {
            this.addAlert({
                content: "Änderungen wurden in der Datenbank gespeichert",
                type: "success",
                timeout: 5
            });
        }
    }

    async saveScreens() {
        if (!this.competence.changed)
            return;
        if (!this.competence.screens)
        return;
        for (let screen of this.competence.screens) {
            if(!screen)
                return;
            if (screen.removed)
                this.saveRemovedScreen(screen);
            if (screen.added)
                this.saveAddedScreen(screen);
        }
    }

    async saveSequence() {
        if (!this.competence.changed)
            return;
        //TODO: create action
        this._dirtyBit = false;
        //filtering nulls
        this.competence.items = this.competence.items.filter((item) => item !== null);
        let res = await GraphQLHelper.query
            (`mutation updateItemSequence($id: ID!, $itemSequence: [ItemSequenceInput!]!) {updateItemSequence(id: $id, itemSequence: $itemSequence) { id}}`
                , { id: this.id, itemSequence: map(this.competence.items, i => ({ item: i.item.id, correct: i.correct?.id, wrong: i.wrong?.id, countForProgress: i.countForProgress })) });
        if(res instanceof GraphQLError) {
            this.addAlert({
                content: res.toString(),
                type: "danger",
                timeout: 10
            });
        }
        //clear undo
        this.booklet = null;
        return res;
    }

    async saveMetadata() {
        let label = document.querySelector(`#label-${this.id}`).value;
        let title = document.querySelector(`#title-${this.id}`).value;
        let abbreviation = document.querySelector(`#abbreviation-${this.id}`).value;
        let allowedTime = document.querySelector(`#allowedTime-${this.id}`).value;
        let reg = /^\d+$/;
        if (reg.test(allowedTime)) {
            allowedTime = parseInt(document.querySelector(`#allowedTime-${this.id}`).value);
        } else {
            allowedTime = -1;
        }
        let res = await GraphQLHelper.query
            (`mutation updateCompetence($id: ID!, $title: String, $label: String, $abbreviation: String, $allowedTime: Int) {updateCompetence(id: $id, title: $title, label: $label, abbreviation: $abbreviation, allowedTime: $allowedTime) { id}}`
                , { id: this.id, title, label, abbreviation, allowedTime });
        if (res instanceof GraphQLError) {
            this.addAlert({
                content: res.toString(),
                type: "danger",
                timeout: 10
            });
        } else {
            //update competence
            let comp = this.findCompetence();
            this.competence = {...comp, title, label, allowedTime, abbreviation };
            //add alert
            this.addAlert({
                content: "Änderungen wurden in der Datenbank gespeichert",
                type: "success",
                timeout: 5
            });
            //reload
            this.requestUpdate();
        }
    }

    renderItem(itemSequence, i) {
        if (!itemSequence){
            return;
        }
        return html`<competence-item 
            id="${itemSequence.item.id}"
            index="${i}"
            .correct="${itemSequence.correct}"
            .wrong="${itemSequence.wrong}"
            title="${itemSequence.item.title}"
            draggable="${this.editable}"
            @dragend=${this.dragEnd}>
        </competence-item>`;
    }

    renderScreen(screen, i) {
        if(!screen)
            return null;
        if (screen.removed == true)
            return null;
        return html`<competence-screen name=${screen.name} language=${screen.language} index=${i} ?editable=${this.editable} id=${screen.id}></competence-screen>`;
    }

    renderMetadata() {
        return html`
            <label for="abbreviation-${this.id}" class="label" style="{margin-right:15px}">Abkürzung: </label> 
            <input type="text" id="abbreviation-${this.id}" name="abbreviation" value="${this.competence?.abbreviation}" ?readonly=${!this.editable} /> <br/>
            <label for="label-${this.id}" class="label" style="{margin-right:15px}">Label: </label>
            <input type="text" id="label-${this.id}" name="label" value="${this.competence?.label}" ?readonly=${!this.editable} /> <br/>
            <label for="title-${this.id}" class="label" style="{margin-right:15px}">Titel: </label>
            <input type="text" id="title-${this.id}" name="title" value="${this.competence?.title}" ?readonly=${!this.editable} /> <br/>
            <label for="allowedTime-${this.id}" class="label" style="{margin-right:10px}">Erlaubte Zeit: </label>
            <input type="number" id="allowedTime-${this.id}" name="allowedTime" value="${parseInt(this.competence?.allowedTime)}" ?readonly=${!this.editable} /> <br/>
            <button ?hidden=${!this.editable} @click="${this.saveMetadata}" class="btn btn-success mdi " data-toggle="tooltip" title="change data">Speichern</button>
        `;
    }

    renderAlerts() {
        let alerts = this.alerts.map((alert, idx) => html`
            <div id="alert-${this.id}-${idx}" class="alert alert-dismissible alert-${alert.type}" role="alert">
                ${alert.content}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>`
        );
        return html`<div id="alerts-${this.id}" class="alerts">${alerts}</div>`;
    }

    render() {
        console.log(`Render competence ${this.id} (expanded: ${this.expanded})`);
        let saveButton = html`<a @click="${this.save}" class="btn btn-sm btn-outline-danger" data-toggle="tooltip" title="Änderungen übernehmen"><i class="mdi mdi-content-save-alert"></i></a>`;
        let undoButton = html`<button @click="${this.undo}" class="btn btn-sm btn-outline-info" data-toggle="tooltip" title="Änderungen Rückgängig machen"><i class="mdi mdi-undo"></i></button>`;
        return html`
            <div id="c-${this.id}" rel="${this.id}" class="competence card col m-2 ${classMap({updated: this.competence?.changed})}">
                <div class="card-title pt-1" id="heading-${this.id}">
                    <button class="btn btn-sm btn-link text-dark" aria-expanded="true" @click=${this.toggleExpand}>
                        <h5>
                            ${this.competence?.abbreviation} - ${this.competence?.title}
                        </h5>
                    </button>                                                              
                    <div class="float-right mr-2 mt-1">
                        ${this.competence?.changed ? undoButton : null}
                        ${this.competence?.changed ? saveButton : null}
                    </div>
                    ${(this.alerts.length > 0) ? this.renderAlerts() : ""}
                    <div id="collapse-${this.id}" class="collapse ${classMap({ show: this.expanded })}" aria-labelledby="heading-${this.id}">
                        <div id="accordion-${this.id}">
                            <div class="card">
                                <div class="card-header" id="items-${this.id}">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseItems-${this.id}" aria-expanded="true" aria-controls="collapseItems-${this.id}">
                                            <i class="mdi open-close-indicator"></i>Items/Videos/Feedback:
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseItems-${this.id}" class="collapse show" aria-labelledby="items-${this.id}" data-parent="#accordion-${this.id}">
                                    <div class="card-body">
                                        <div ?hidden=${!this.editable}>
                                            <ui5-combobox @input=${this.searchItems} filter="StartsWith" id="combo-${this.id}" placeholder="Item ID (min 3 Zeichen)" ui5-combobox=""></ui5-combobox>
                                            <button @click="${this.addItem}" class="btn btn-success mdi mdi-plus" data-toggle="tooltip" title="add item">Item einfügen</button>
                                        </div>
                                        <div id="container-${this.id}" class="itemcontainer card-body pt-0">
                                            ${this.competence?.items.map((item, i) => this.renderItem(item, i))}
                                        </div>                                
                                    </div>
                                </div>    
                            </div>

                            <div class="card">
                                <div class="card-header" id="screens-${this.id}">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseScreens-${this.id}" aria-expanded="false" aria-controls="collapseScreens-${this.id}">
                                            <i class="mdi open-close-indicator"></i>Screens:
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseScreens-${this.id}" class="collapse" aria-labelledby="screens-${this.id}" data-parent="#accordion-${this.id}">
                                    <div class="card-body">
                                        <div ?hidden=${!this.editable}>
                                            <input type="text" id="screens-${this.id}" name="screens" value="">
                                            <button @click="${this.addScreen}" class="btn btn-success mdi mdi-plus" data-toggle="tooltip" title="add Screen">Screen einfügen</button>
                                        </div>
                                        <div id="container-${this.id}" class="screencontainer card-body pt-0">
                                            ${this.competence?.screens?.map((screen, s) => this.renderScreen(screen, s))}
                                        </div>    
                                    </div>
                                </div>
                            </div>                                        
                           
                            <div class="card">
                                <div class="card-header" id="others-${this.id}">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOthers-${this.id}" aria-expanded="false" aria-controls="collapseOthers-${this.id}">
                                            <i class="mdi open-close-indicator"></i>Metadaten:
                                        </button>
                                    </h5>
                                    </div>
                                    <div id="collapseOthers-${this.id}" class="collapse" aria-labelledby="others-${this.id}" data-parent="#accordion-${this.id}">
                                        <div class="card-body">
                                            ${this.renderMetadata()}
                                        </div>
                                    </div>
                                </div>
                            </div>     
                        </div>        
                    </div>
                </div>
            </div>
        `;
    }
}

window.customElements.define("competence-container", CompetenceContainer);