import { html } from "lit-element";
import StoreElement from "../components/StoreElement";
import cloneDeep from "lodash-es/cloneDeep";

class AddCompetenceElement extends StoreElement {
    static get properties() {
        return {
            error: {type: String, attribute: false},
            newSection: { type: Number },
            oldSection: { type: Number },
            competences: { type: Array },
            newSectionName: { type: String, attribute: false },
            newSectionLabel: { type: String, attribute: false },
            newSectionAbbrev: { type: String, attribute: false },
        };
    }

    constructor() {
        super();
        this.newSection = -1;
        this.oldSection = -1;
        this.competences = [];
        this.booklet = null;
        this.newSectionName = "";
        this.newSectionLabel = "";
        this.newSectionAbbrev = "";
        this.editable = false;
    }

    createRenderRoot() {
        return this;
    }

    storeChanged() {
        const state = this.store.getState();
        this.editable = state.user.rights.write && state.version === "master";
        let competences = state.competences;
        let booklet = state.booklet;
        if(!competences.fetched) {
            console.log("Need to fetch data");
            this.store.dispatch(this.store.thunks.getCompetences());
        }
        this.booklet = cloneDeep(booklet);
        this.competences = cloneDeep(competences.data).filter(e => !this.booklet.competences.some(c => c.id === e.id));
        console.log("Store changed", this.competences.length);
    }

    async addSection() {
        let idx = this.renderRoot.querySelector("#available").selectedIndex;
        let c = this.competences[idx];
        if(c) {
            this.store.dispatch(this.store.thunks.addCompetenceToCurrentBooklet(c.id));
        }
    }

    removeSection() {
        let idx = this.renderRoot.querySelector("#used").selectedIndex;
        let c = this.booklet.competences[idx];
        if(c) {
            this.store.dispatch(this.store.thunks.removeCompetenceFromCurrentBooklet(c.id));
        }
    }

    moveUp() {
        let idx = this.renderRoot.querySelector("#used").selectedIndex;
        if(idx === 0 || idx >= this.booklet.competences.length)
            return;
        let c = this.booklet.competences[idx];
        this.booklet.competences[idx] = this.booklet.competences[idx-1];
        this.booklet.competences[idx-1] = c;
        this.store.dispatch(this.store.thunks.setCompetencesInCurrentBooklet(this.booklet.competences.map(c => c.id)));
        this.oldSection--;
    }

    moveDown() {
        let idx = this.renderRoot.querySelector("#used").selectedIndex;
        //if we're last, we cannot go down
        if(idx < 0 || idx >= this.booklet.competences.length-1)
            return;
        let c = this.booklet.competences[idx];
        this.booklet.competences[idx] = this.booklet.competences[idx+1];
        this.booklet.competences[idx+1] = c;
        this.store.dispatch(this.store.thunks.setCompetencesInCurrentBooklet(this.booklet.competences.map(c => c.id)));
        this.oldSection++;
    }

    updatePreview(e) {
        console.log(`Set ${e.target.id} on this to ${e.target.value}`);
        this[e.target.id] = e.target.value;
    }

    createNewSection() {
        let name = this.newSectionName;
        let label = this.newSectionLabel;
        let abbreviation = this.newSectionAbbrev;
        if(!name) {
            alert("Ein Name muss angegeben werden");
            return;
        }
        console.log("Create new section with name", name);
        this.store.dispatch(this.store.thunks.createSection({name, label, abbreviation}));
        //reset
        this.resetNewSection();
    }

    resetNewSection() {
        this.newSectionName = "";
        this.newSectionLabel = "";
        this.newSectionAbbrev = "";
    }

    printLabel(competence) {
        let label = competence.label ? ` (${competence.label})` : "";
        let abbrev = competence.abbreviation ? `${competence.abbreviation} - ` : "";
        return `${abbrev}${competence.title}${label}`;
    }

    renderSectionPreview() {
        let preview = {
            abbreviation: this.newSectionAbbrev,
            title: this.newSectionName,
            label: this.newSectionLabel
        };
        let previewLabel = this.printLabel(preview);
        if(previewLabel.trim() === "")
            return null;
        return html`<div class="form-group col-12">Vorschau: <span class="alert alert-secondary">${previewLabel}</span></div>`;
    }

    render() {
        return html`
        <div>
            <button class="btn btn-outline-primary" data-toggle="modal" data-target="#modifySections" ?hidden=${!this.editable} ?disabled=${!this.editable}>Testabschnitte bearbeiten</button>
            <div class="modal fade show" id="modifySections" tabindex="-1">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Abschnitte ändern</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            ${this.error && html`<div class="alert alert-danger">${this.error}</div>`}
                            <form @submit=${(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                <div class="form-group row justify-content-center">
                                    <div class="col-5">
                                        <label for="available">Verfügbare Abschnitte</label>
                                        <select class="form-control" id="available" name="available" @change=${(e) => this.newSection = e.target.selectedIndex} size=10>
                                            ${this.competences.map(c => html`<option value=${c.id}>${this.printLabel(c)}</option>`)}
                                        </select>
                                    </div>
                                    <div class="col flex-column" style="max-width: 5rem; height: 100%; align-self: center;">
                                        <button class="btn btn-outline btn-info" ?disabled=${this.newSection === -1} @click=${this.addSection} ><i class="mdi mdi-arrow-right-bold" aria-hidden="true"></i></button>
                                        <div class="p-1"></div>
                                        <button class="btn btn-outline btn-info" ?disabled=${this.oldSection === -1} @click=${this.removeSection} ><i class="mdi mdi-arrow-left-bold" aria-hidden="true"></i></button>
                                    </div>
                                    <div class="col-5">
                                        <label for="used">Benutzte Abschnitte</label>
                                        <select class="form-control" id="used" name="used" size=10 @change=${(e) => this.oldSection = e.target.selectedIndex} .selectedIndex=${this.oldSection}>
                                            ${this.booklet.competences.map(e => html`<option value=${e.id}>${this.printLabel(e)}</option>`)}
                                        </select>
                                    </div>
                                    <div class="col flex-column" style="max-width: 5rem; height: 100%; align-self: center;">
                                        <button class="btn btn-outline btn-info" ?disabled=${this.oldSection <= 0} @click=${this.moveUp} ><i class="mdi mdi-arrow-up-bold" aria-hidden="true"></i></button>
                                        <div class="p-1"></div>
                                        <button class="btn btn-outline btn-info" ?disabled=${this.oldSection === -1 || this.oldSection >= this.booklet.competences.length -1} @click=${this.moveDown} ><i class="mdi mdi-arrow-down-bold" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                                <hr>
                                <div class="form-group row justify-content-center">
                                    <div class="col-12">
                                        <h5>Neuer Abschnitt</h5>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    ${this.renderSectionPreview()}
                                    <div class="form-group col-5">
                                        <label for="newSectionName">Name</label>
                                        <input class="form-control" type="text" name="newSectionName" id="newSectionName" @keyup=${this.updatePreview} .value=${this.newSectionName} placeholder="Name des Abschnitts" size="40" />
                                    </div>
                                    <div class="form-group col-5">
                                        <label for="newSectionLabel">Label (Interner Name)</label>
                                        <input class="form-control" type="text" name="newSectionLabel" id="newSectionLabel" @keyup=${this.updatePreview} .value=${this.newSectionLabel} placeholder="Label des Abschnitts" size="5" />
                                    </div>
                                    <div class="w-100"></div>
                                    <div class="form-group col-5">
                                        <label for="newSectionAbbrev">Abkürzung (Abkürzung vor dem Abschnitt)</label>
                                        <input class="form-control" type="text" name="newSectionAbbrev" id="newSectionAbbrev" @keyup=${this.updatePreview} .value=${this.newSectionAbbrev} placeholder="Abkürzung des Abschnitts" size="5" />
                                    </div>
                                    <div class="col-12">
                                        <button class="btn btn-primary" @click=${this.createNewSection}>Erstellen</button>
                                        <button class="btn btn-outline-secondary" @click=${this.resetNewSection}>Zurücksetzen</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Schließen</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        `;
    }
}

customElements.define("add-competence", AddCompetenceElement);
