import { html } from "lit-element";
import StoreElement from "../components/StoreElement";
import * as actions from "../service/actionTypes";

export class CompetenceScreen extends StoreElement {
    static get properties() {
        return {
            id: { type: String },
            name: { type: String },
            errors: { type: Array, attribute: false },
            index: { type: Number },
            editable: {type: Boolean}
        };
    }

    get competence() {
        return this.closest("competence-container").id;
    }

    constructor() {
        super();
        this.name = "";
        this.id = null;
        this.language = "de-DE";
        this.errors = [];
        this.class = "";
        this.index = 0;
        this.editable = false;
    }

    storeChanged() {
        const state = this.store.getState();
        this.editable = state.user.rights.write && state.version === "master";
    }

    remove() {
        this.store.dispatch({ type: actions.REMOVE_SCREEN_FROM_COMPETENCE, removedScreen: {element: this.id, competence: this.competence }});
    }

    createRenderRoot() {
        return this;
    }

    renderDeleteButton() {
        return html`
        <div ?hidden=${!this.editable} class="float-right mr-2 mt-1">
            <a @click="${this.remove}" class="btn btn-sm btn-outline-secondary" data-toggle="tooltip" title="Löschen"><i class="mdi mdi-delete"></i></a>
        </div>
        `;
    }

    render() {
        let border = this.index == 0 ? "" : "border-top-0";
        return html`
        <div class="row border ${border}">
            <div id="${this.id}" class="${border} col-12 screen p-3">
                <div class="row justify-content-between">
                    <div class="title col">
                        ${this.renderDeleteButton()}
                        <a href="/screen/${this.id}">${this.name}</a>
                        ${this.errors.map(error => html`
                            <br /><span class="text-${error.type}"><i class="mdi mdi-alert"></i>${error.msg}</span>                             
                        `)} 
                    </div>
                </div>     
            </div>         
        </div>`;
    }
}

window.customElements.define("competence-screen", CompetenceScreen);