import StoreElement from "./StoreElement";

class Navbar extends StoreElement {
    connectedCallback() {
        super.connectedCallback();
        this.selectNavElement();
    }

    createRenderRoot() {
        return this;
    }

    selectNavElement() {
        const path = globalThis.location.pathname;
        for(let el of this.querySelectorAll(".nav-item>.nav-link")) {
            if(path.startsWith(el.pathname)) {
                el.classList.add("active");
            }
        }
        this.storeChanged();
    }

    storeChanged() {
        let state = this.store.getState();
        //update elements
        for(let el of this.querySelectorAll(".nav-item")) {
            let rights = el.getAttribute("data-rights") ?? "query";
            let editable = el.getAttribute("editable-version") == "master" && state.version !="master";         
            el.hidden = !(state.user.rights[rights] ?? false) || editable;
        }
    }
}

window.customElements.define("decos-navbar", Navbar);