import { html } from "lit-element";
import StoreElement from "../../components/StoreElement";

class JobSelector extends StoreElement {
    static get properties() {
        return {
            data: { type: Array },
            beruf: { type: Object },
            schwerpunkt: { type: Object },
            handlungsfeld: { type: Object },
            situation: { type: Object },
        };
    }

    constructor() {
        super();
    }

    get job() {
        return {
            job: this.beruf?.name,
            kldb: this.schwerpunkt?.kldb,
            schwerpunkt: this.schwerpunkt?.name,
            handlungsfeld: this.handlungsfeld?.id ? this.handlungsfeld?.id + " - " + this.handlungsfeld?.name : null,// temp solution
            situation: this.situation?.name
        };
    }

    connectedCallback() {
        super.connectedCallback();
        this.data = null;
        this.reset();
        this.getJobs();
    }

    async fetchBookletsData(url) {
        const res = await fetch(url);
        if (res.error) {
            console.log(res.error);
            return [];
        } else {
            return await res.json();
        }
    }

    async getJobs() {
        this.data = await this.fetchBookletsData("/lib/bst.json");
    }

    selectJob(e) {
        let index = e.target.selectedIndex - 1;
        let beruf = this.data[index];
        if (!beruf) {
            this.reset();
            return;
        }
        //reset everything if a job changes
        this.reset();
        this.beruf = beruf;
        //if we only have one job
        if(beruf.schwerpunkte.length === 1) {
            this.schwerpunkt = beruf.schwerpunkte[0];
            //reset handlungsfeld
            this.querySelector("#handlungsfeld").selectedIndex = 0;
            console.log("Changed to single schwerpunkt job");
        } else {
            this.querySelector("#schwerpunkt").selectedIndex = 0;
        }
    }

    selectSchwerpunkt(e) {
        if(!this.beruf) {
            console.warn("No job selected");
            return;
        }
        // let kldbNr = e.target.value;
        //reset
        this.situation = null;
        this.handlungsfeld = null;
        this.schwerpunkt = this.beruf.schwerpunkte.find(b => b.name == e.target.value);
        if(!this.schwerpunkt) {
            this.querySelector("#schwerpunkt").selectedIndex = 0;
            return;
        }
        //reset handlungsfelder
        this.querySelector("#handlungsfeld").selectedIndex = 0;
    }

    selectHandlungsfeld(e) {
        let index = e.target.selectedIndex - 1;
        this.handlungsfeld = this.schwerpunkt.handlungsfelder[index];
        this.querySelector("#situation").selectedIndex = 0;
    }

    selectSituation(e) {
        let index = e.target.selectedIndex;
        if(index === 0) {
            return;
        }
        this.situation = this.handlungsfeld.situationen[index-1];
    }

    createRenderRoot() {
        return this;
    }

    storeChanged() { }

    reset() {
        this.beruf = null;
        this.schwerpunkt = null;
        this.handlungsfeld = null;
        this.situation = null;
    }

    render() {
        console.log(`Render with job: ${this.beruf?.id}, kldb: ${this.schwerpunkt?.kldb}, schwp: ${this.schwerpunkt?.id} / ${this.schwerpunkt?.name}, handlung: ${this.handlungsfeld?.id}, situation: ${this.situation?.id}`);
        return html`
        <div class="structure" >
            <div class="mycolumn mylabel span1to2">
                <label>Beruf</label>
            </div>
            <div class="span2to8">
                <select id="job" name="job" size="1" @change=${this.selectJob} class="mycolumn">
                    <option value="none"></option>
                    ${this.data?.map(e => html`<option value=${e.id} ?selected=${e.id==this.beruf?.id}>${e.name}</option>`)}
                </select>
            </div>
            <div class="mycolumn mylabel span8to9">
                <label >KLDB</label>
            </div>
            <div class="span9to10">
                <input id="kldb" name="kldb" list=kldbs .value="${this.schwerpunkt?.kldb ?? ""}"
                     class="myinput short">
                <datalist id="kldbs">
                    <select name="kldb">
                        ${this.beruf?.schwerpunkte?.map(e => html`<option value=${e.kldb} ?selected=${e.kldb==this.schwerpunkt?.kldb}></option>`)}
                    </select>
                </datalist>
            </div>
            <div class="mycolumn mylabel span1to2">
                <label>Schwerpunkt</label>
            </div>
            <div class="span2to10">
                <select id="schwerpunkt" name="schwerpunkt" size="1"
                    @change=${this.selectSchwerpunkt} class="mycolumn">
                    <option value="none" ?selected=${!this.schwerpunkt}></option>
                    ${this.beruf?.schwerpunkte?.map(e => html`<option value=${e.name} ?selected=${this.schwerpunkt?.name === e.name}>${e.name}</option>`)}
                </select>
            </div>
            <div class="mycolumn mylabel span1to2">
                <label>Handlungsfeld</label>
            </div>
            <div class="span2to10">
                <select id="handlungsfeld" name="handlungsfeld" size="1" @change="${this.selectHandlungsfeld}" class="mycolumn">
                    <option value="none" ?selected=${!this.handlungsfeld}></option>
                    ${this.schwerpunkt?.handlungsfelder?.map(e => html`<option value=${e.id}
                        ?selected=${e.id==this.handlungsfeld?.id}>${e.id + " - " +  e.name}</option>`)}
                </select>
            </div>
            <div class="mycolumn mylabel span1to2">
                <label>Handl.situation</label>
            </div>
            <div class="span2to10">
                <select id="situation" name="situation" size="1" 
                @change="${this.selectSituation}" class="mycolumn">
                    <option value="none" ?selected=${!this.situation}></option>
                    ${this.handlungsfeld?.situationen?.map(e => html`<option value=${e.id}
                        ?selected=${e.id===this.situation?.id}>${e.name}</option>`)}
                </select>
            </div> 
        </div>
        `;
    }
}

window.customElements.define("job-selector", JobSelector);
