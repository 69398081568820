import { LitElement, html } from "lit-element";
import GraphQLHelper from "../service/graphql";

class ItemPreview extends LitElement {
    static get properties() {
        return {
            itemid: { type: String, reflected: true },
            type: { type: String },
            itemData: { type: Object }
        };
    }

    constructor() {
        super();
        this.itemData = { rawData: null, assets: null};
    }

    async getItemProp() {
        let res = await GraphQLHelper.query(`query itemById($id: ID!) { itemById(id: $id){ id, type, details(languageId: "de-DE"){rawData assets { assetName id file { url } }}} }`, { id: this.itemid });
        this.type = res.data.itemById?.type;
        this.itemData = {
            rawData: res.data.itemById?.details?.rawData,
            assets: res.data.itemById?.details?.assets
        };
    }

    firstUpdated(changedProperties) {
        changedProperties.forEach((oldValue, propName) => {
            console.log(`${propName} changed. oldValue: ${oldValue}`);
        });
        this.getItemProp();
    }

    render() {
        if(!this.itemData.rawData) {
            return html`Loading ...`;
        }
        return html`<image-map .data=${this.itemData}></image-map>`;
    }
}

window.customElements.define("item-preview", ItemPreview);