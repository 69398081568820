import { html, css } from "lit-element";
import GraphQLHelper, { GraphQLError }  from "../../service/graphql";
import StoreElement from "../../components/StoreElement";
class ItemSelector extends StoreElement {
    static get properties() {
        return {
            reliantPlus: { type: Number },
            inputs: { type: Object }
        };
    }

    static get styles() {
        return css`
            input {
                font-family: Arial;
                font-size: 14px;
                width: 200px;
                height: 35px;
                border: 3px solid #53a8c7 ;
            }
            .button{
                background:#53a8c7;
                color: black;
                font-family: Arial;
                font-size: 20px;
                height: 35px;
                width: 35px;
                padding-top: 1px;	
                border: 0.5px solid black;
            }
        `;
    }

    constructor() {
        super();
        this.searchData = null;
        this.formData = {};
        this.reliantPlus = 0;
        this.inputs = [];
    }

    connectedCallback() {
        super.connectedCallback();
        (async() => {
            await this.searchItem("");
            this.requestUpdate();
        })();
    }

    storeChanged() { }

    get reliant() {
        if ( Object.entries(this.formData).length == 0){
            return null;
        } else {
            return JSON.stringify(this.formData);
        }
    }

    async validateSearch(e) {
        //workaround searchIndex entry
        let searchData = this.searchData?.filter(e => {
            return e !== null;
           });
        let item = searchData?.find(i => i.title == e.target.value);
        //create item needs itemtype
        if (item){
            this.formData[e.target.id] = { id: item.id, name: item.title};
        }
        else{
            this.formData[e.target.id] = { id: "", name: e.target.value};
        }
        
    }

    async searchItem(search) {
        // let search = e.target.value.toLowerCase();
        let res = await GraphQLHelper.query(`query itemSearch($search: String!) {
            itemSearch(expression: $search) { title id }
        }`, { search });
        if(res instanceof GraphQLError) {
            return;
        } 
        this.searchData = res.data.itemSearch;
    }

    handelPlus() {
        this.reliantPlus = this.reliantPlus + 1;
    }

    handelMinus() {
        if (this.reliantPlus <= 0)
            return;
        this.reliantPlus = this.reliantPlus - 1;
    }

    reset() {
        this.formData = {};
        this.renderRoot.querySelector("input").value = "";
        this.reliantPlus = 0;
    }

    getInputs() {
        this.inputs = [html`<input id="reliant_0" name="reliant_0" list="d_reliant" @change="${this.validateSearch}" autocomplete="off">`];
        for (let i = 0; i < this.reliantPlus; i++) {
            this.inputs.push(html`<input id="reliant_${i + 1}" name="reliant_${i + 1}" list="d_reliant" @change="${this.validateSearch}" autocomplete="off">`);
        }
        return this.inputs;
    }

    render() {
        return html`
            ${this.getInputs()}
            <datalist id="d_reliant">${this.searchData?.map(e => html`<option value=${e.title} id=${e.id} >${e.title}</option>`)}</datalist>
            <input @click=${this.handelPlus} id="reliant_plus" type="button" value="+" class="button">
            <input @click=${this.handelMinus} id="reliant_minus" type="button" value="-" class="button">
        `;
    }
}


window.customElements.define("item-selector", ItemSelector);
