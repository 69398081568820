import { html } from "lit-element";
import StoreElement from "../components/StoreElement";

export default class BookletEdit extends StoreElement {
    #expandedSections = new Set();

    static get properties() {
        return {
            id: { type: String },
            booklet: { type: Object },
            user: { type: Object },
            ready: { type: Boolean, attribute: false },
            excelExportOptions: { type: Object },
            xliffExportOptions: { type: Object },
            xliffImportOptions: { type: Object },
            editTitle: { type: Boolean },
        };
    }

    connectedCallback() {
        super.connectedCallback();
        this.ready = false;
        this.excelExportOptions = {
            language: "all",
            assets: false,
        };
        this.xliffExportOptions = {
            language: "ar-arb",
            pattern1: true,
            pattern2: false,
            pattern3: false,
            target: false,
            forceOverwrite: false
        };
        this.xliffImportOptions = {
            language: "",
            forceOverwrite: false,
            dryrun: false
        };
        this.editTitle = false;
        this.requestData();
        //load query parameteres
        this.parseParams();
        //listen to changes
        window.addEventListener("popstate", this.parseParams.bind(this));
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener("popstate", this.parseParams.bind(this));
    }

    parseParams() {
        let params = new URLSearchParams(window.location.search);
        console.log(`Reading params: ${params}`);
        this.#expandedSections = new Set();
        if(params.has("expanded")) {
            this.#expandedSections = new Set(params.get("expanded").split(",").map(i => parseInt(i)));
        }
        console.log(`Expanded Sections: ${Array.from(this.#expandedSections)}`);
        this.requestUpdate();
    }

    // No shadow root currently
    createRenderRoot() {
        return this;
    }

    storeChanged() {
        let state = this.store.getState();
        console.log("Store changed", state.booklet);
        this.booklet = state.booklet;
        this.user = state.user;
        this.ready = true;
        this.editable = state.user.rights.write;
        this.version = state.version === "master" ? state.version : `version/${state.version}`;
        if (this.version != "master"){
            this.editable = false;
        }
        this.languages = state.languages;
        this.requestUpdate();
    }

    async requestData() {
        this.store.dispatch(this.store.thunks.getBooklet(this.id));
        this.store.dispatch(this.store.thunks.loadLanguages());
    }

    async exportExcel() {
        try {
            let data = { version: this.version, assets: false};
            let booklet = {
                booklet: this.booklet.id
            };
            if(this.excelExportOptions.language == "all" && !!this.booklet.languages) {
                booklet.languages = this.booklet.languages;
            } else {
                booklet.languages = [this.excelExportOptions.language];
            }
            data.booklets = [booklet];
            data.assets = this.excelExportOptions.assets;
            let resAx = await fetch(new URL("/export/v2", globalThis.graphql_url).toString(), {
                    method: "post",
                    responseType: "stream",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${this.store.getState().token}`
                    },
                    body: JSON.stringify(data)
            });
            let blob = await resAx.blob();
            let fileUrl = window.URL.createObjectURL(blob);
            //create dummy anchor (because we otherwise cannot set the filename :()
            let el = document.createElement("a");
            //must! be attached
            document.body.appendChild(el);
            el.href = fileUrl;
            el.download = this.booklet?.title + ".zip";
            el.click();
            //now remove
            el.remove();
            //be kind and give the resource back
            window.URL.revokeObjectURL(fileUrl);
        } catch (e) {
            console.error(e);
        }
    }

    async exportXliff() {
        try {
          let tmp = 0;
          if(this.xliffExportOptions.pattern1)
                tmp = tmp | 1;
          if(this.xliffExportOptions.pattern2)
                tmp = tmp | 2;
          if(this.xliffExportOptions.pattern3)
                tmp = tmp | 4;
          let ecStr = (tmp >>> 0).toString(2).padStart(4, "0");

          let resAx = await fetch(new URL(`/xliff/default/${this.xliffExportOptions.language}/${this.booklet.id}`, globalThis.xliff_url).toString(),
            {
              method: "get",
              responseType: "stream",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.store.getState().token}`,
                "exportControl": ecStr,
                "notarget": !this.xliffExportOptions.target,
                "ib_versions": this.version,
                "forceOverwrite": this.xliffExportOptions.forceOverwrite
              }
          });
          let bookletName = this.booklet.title.replace(/[\\/ÄÖÜäöüß, ]+/g, (match) => {
            const replacements = {
              "/": "-",
              "Ä": "Ae",
              "Ö": "Oe",
              "Ü": "Ue",
              "ä": "ae",
              "ö": "oe",
              "ü": "ue",
              "ß": "ss",
              " ": "_",
              ",": "und"
            };
          
            // Use the replacements or keep the original character if not found
            return replacements[match] || match;
          });
          
          // 35640573-4e13-4168-9c5e-f32271e078d2_Landwirtin_target_off_P0001_de-DE
          bookletName = bookletName.split(" ").join("").split(":").join("");
          let target = this.xliffExportOptions.target ? "target_on" : "target_off";
          let blob = await resAx.blob();
          let fileUrl = window.URL.createObjectURL(blob);
          let el = document.createElement("a");
          document.body.appendChild(el);
          el.href = fileUrl;
          el.download = `${this.booklet.id}_${bookletName}_${target}_P${ecStr}_${this.xliffExportOptions.language}.zip`;
          el.click();
          el.remove();
          window.URL.revokeObjectURL(fileUrl);
        } catch (e) {
            console.error(e);
        }
    }
    async importXliff(e) {
        e.preventDefault();
        let uploads = e.target.upload;
        if (uploads.files.length === 0) return;
        try {
            console.log(uploads.files[0]);
            const formData = new FormData();
            formData.append("upload", uploads.files[0]);
            let res = await fetch(new URL(
              `/xliff/default/${this.xliffImportOptions.language}/${this.booklet.id}`, globalThis.xliff_url).toString(),
              {
                method: "POST",
                responseType: "stream",
                headers: {
                  "Authorization": `Bearer ${this.store.getState().token}`,
                  "forceOverwrite": this.xliffImportOptions.forceOverwrite,
                  "dryrun": this.xliffImportOptions.dryrun,
                },
                body: formData,
              });
            if (res.error) {
                res.json({ error: `Failed to load -> ${e}` });
                return [];
            } else {
                return await res.json();
            }
        } catch (e) {
            console.log(`Failed to load -> ${e}`);
        }
    }

    toggleSection(evt) {
        console.log("Toggle section", evt);
        //map sender id to index in competences
        let c = this.booklet.competences.findIndex((e) => e.id === evt.detail.id);
        if (c === -1) {
            console.error("Invalid section", evt);
            return;
        }
        if (evt.detail.expanded) {
            //add
            this.#expandedSections.add(c);
        } else {
            this.#expandedSections.delete(c);
        }
        //now update nav
        const url = new URL(window.location);
        url.searchParams.set("expanded", Array.from(this.#expandedSections).join(","));
        window.history.pushState(null, null, url);
        console.log(this.#expandedSections);
        //request re-render
        this.requestUpdate();
    }

    onChangeMetaData(e) {
        try {
            let old = this.booklet[e.detail.name];
            //only change if we actually changed the value
            if(old === e.detail.value) {
                return;
            }
            this.booklet[e.detail.name] = e.detail.value;
            if(e.detail.name === "tags") {
                this.booklet.tags = this.booklet.tags.split(",").map(e => e.trim());
            }
            this.booklet.changed = true;
            //only change locally
            this.changeState({ booklet: this.booklet });
        } catch(e) {
            console.error("Failed to set value", e);
        }
    }

    saveMetaData() {
        this.store.dispatch(this.store.thunks.updateBooklet(this.booklet.id, {
                title: this.booklet.title,
                languages: this.booklet.languages,
                details: this.booklet.details,
                tags: this.booklet.tags,
                testHeftName: this.booklet.testHeftName,
                testHeftId: this.booklet.testHeftId,
                berufId: parseInt(this.booklet.berufId)
            }));
    }

    resetMetaData() {
        //just reload the data
        this.store.dispatch(this.store.thunks.getBooklet(this.booklet.id));
    }

    renameTitle() {
        let name = this.renderRoot.querySelector("#newTitle").value;
        if(!name) {
            return;
        }
        this.store.dispatch(this.store.thunks.updateBooklet(this.booklet.id, { title: name }));
        this.editTitle = false;
    }

    renderBreadcrumb() {
        let parts = this.booklet.title.split("/");
        let title = parts.pop();
        if(this.editable) {
          if(this.editTitle) {
            title = html`<input type="text" name="newTitle" id="newTitle" .value=${this.booklet.title} /><button @click=${this.renameTitle} class="btn"><i class="mdi mdi-content-save-alert"></i></button>`;
          } else {
            title = html`<a href=# @click=${() => {this.editTitle = true;}}>${title} <i class="mdi mdi-pencil"></i></a>`;
          }
        }
        let breadcrumb = [html`<li class="breadcrumb-item"><a href="/booklets">Booklets</a></li>`];
        for(let n of parts) {
        breadcrumb.push(html`<li class="breadcrumb-item"><a href="/booklets?filter=${n}">${n}</a></li>`);
        }
        breadcrumb.push(html`<li class="breadcrumb-item active">${title}</li>`);
        return html`
        <nav class="col">
          <ol class="breadcrumb align-items-center">
            ${breadcrumb}
          </ol>
        </nav>`;
    }

    renderLanguages() {
        return [
            html`<div class="col-3">Vorhandene&nbsp;Sprachen</div>`,
            html`<div class="col" style="padding-left: 30px;">${this.booklet.languages.join(", ")}</div>`
        ];
    }

    renderMetadata() {
        let bookletMetadata = Object.entries(this.booklet).filter(([k,]) => (!["competences", "title", "languages", "changed"].includes(k)));
        console.log(bookletMetadata);
        let fields = [];
        for(let [k,v] of bookletMetadata) {
            if(k === "tags" && !!v) {
                v = v?.join(", ");
            }
            fields.push(
                html`
                <div class="col-3">${k}</div>
                <div class="col-9"><data-input @onChangeData=${this.onChangeMetaData} .editable=${k !== "id"  && !!this.editable} .key=${k} .value=${v}></data-input></div>
                `
            );
        }
        return html`
        <div class="col-3">Metadaten</div>
        <div class="col-9">
          <div class="row justify-content-end">
            <div class="col-sm-auto py-1">
              <button @click="${this.saveMetaData}" ?disabled=${!this.booklet.changed || !this.editable}
                ?hidden=${!this.editable} class="btn btn-sm btn-outline-danger" data-toggle="tooltip"
                title="Änderungen übernehmen">
                <i class="mdi mdi-content-save-alert"></i>
              </button>
              <button @click="${this.resetMetaData}" ?disabled=${!this.booklet.changed || !this.editable}
                ?hidden=${!this.editable} class="btn btn-sm btn-outline-info" data-toggle="tooltip"
                title="Änderungen rückgängig machen">
                <i class="mdi mdi-undo"></i>
              </button>
            </div>
          </div>
          <div class="row">${fields}</div>
        </div>`;
    }

    renderExport() {
        if (!this.user.rights.write) return null;
        let langOptions = this.languages.map((l) => html`<option value="${l}">${l}</option>`);
        return html` 
        <div class="col-3">Export/Import</div>
        <div class="col">
          <ul class="nav nav-tabs border-bottom-0">
            <li role="presentation" class="nav-item">
              <a class="nav-link active" data-toggle="tab" href="#export-xliff">Export XLIFF</a>
            </li>
            <li role="presentation" class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#import-xliff">Import XLIFF</a>
            </li>
            <li role="presentation" class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#export-excel">Export Excel</a>
            </li>
          </ul>
          <div class="tab-content border">
            <div id="export-xliff" style="min-height: 100px;" class="tab-pane container show active">
              ${this.renderExportXliff(langOptions)}
            </div>
            <div id="import-xliff" style="min-height: 100px;" class="tab-pane container fade">
              ${this.renderImportXliff(langOptions)}
            </div>
            <div id="export-excel" style="min-height: 100px;" class="tab-pane container fade">
              ${this.renderExportExcel(langOptions)}
            </div>
          </div>
        </div>`;
    }
    renderExportXliff(langOptions) {
        return html` 
        <div class="form-group row">
          <label for="sel_lang_xliff" class="col-sm-4 col-form-label">Sprachauswahl (Zielsprache)</label>
          <div class="col-sm-8">
            <select style="min-width: 150px;" name="languages" id="sel_lang_xliff" class="custom-select form-control" @change="${(e) =>
                        (this.xliffExportOptions.language = e.target.value)}">
              ${langOptions}
            </select>
          </div>
        </div>
         <div class="form-check form-check-inline">
          <label class="form-check-label">Exportoptionen </label>
          <div class="col-sm-10">
          <input class="form-check-input" type="checkbox" id="force_overwrite" name="force_overwrite" @change="${(e) =>
                      (this.xliffExportOptions.forceOverwrite = e.target.checked)}" />
          <label class="form-check-label" for="cb_ec_1">forceOverwrite</label>
          <!--
            <input class="form-check-input" checked type="checkbox" id="cb_ec_1" name="cb_ec" @change="${(e) =>
                        (this.xliffExportOptions.pattern1 = e.target.checked)}" />
            <label class="form-check-label" for="cb_ec_1">Pattern 1</label>
            <input class="form-check-input" type="checkbox" id="cb_ec_2" name="cb_ec" @change="${(e) =>
                        (this.xliffExportOptions.pattern2 = e.target.checked)}" />
            <label class="form-check-label" for="cb_ec_2">Pattern 2</label>
            <input class="form-check-input" type="checkbox" id="cb_ec_3" name="cb_ec" @change="${(e) =>
                        (this.xliffExportOptions.pattern3 = e.target.checked)}" />
            <label class="form-check-label" for="cb_ec_3">Pattern 3</label>-->
            <input class="form-check-input" type="checkbox" id="cb_target" name="target" @change="${(e) =>
                        (this.xliffExportOptions.target = e.target.checked)}"/>
            <label class="form-check-label" for="cb_target">target</label>
          </div> 
        </div>
        <div class="form-group row">
          <div class="col-sm-10 pt-3">
            <button class="btn btn-primary" @click="${this.exportXliff}">
              <i class="mdi mdi-download"></i>Export
            </button>
          </div>
        </div>`;
    }

    renderImportXliff(langOptions) {
        return html`
        <div class="container" style="position: relative; display: inline-block;">
          <form class="form col" @submit=${this.importXliff}>
            <div class="form-group row">
              <label for="sel_lang_xliff" class="col-sm-4 col-form-label">Sprachauswahl (Zielsprache)</label>
              <div class="col-sm-8">
                <select style="min-width: 150px;" name="languages" id="sel_lang_xliff"
                  class="custom-select form-control" @change="${(e) =>(this.xliffImportOptions.language = e.target.value)}">
                  ${langOptions}
                </select>
              </div>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">Importoptionen </label>
              <div class="col-sm-10">
                <input class="form-check-input" type="checkbox" id="forceOverwrite" name="forceOverwrite" @change="${(e) =>(this.xliffImportOptions.forceOverwrite = e.target.checked)}">
                <label class="form-check-label" for="forceOverwrite">forceOverwrite</label>
                <input class="form-check-input" type="checkbox" id="dryrun" name="dryrun" @change="${(e) =>(this.xliffImportOptions.dryrun = e.target.checked)}">
                <label class="form-check-label" for="dryrun">dryrun</label>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-10 pt-3">
                <input type="file" class="form-control-file" id="upload" name="upload" required />
              </div>
              <div class="col-sm-10 pt-3">
                <button type="submit" class="btn btn-primary" disabled><i class="mdi mdi-download"></i>Import</button>
              </div>
            </div>
          </form>
        </div>
      </div>`;
    }

    renderExportExcel(langOptions) {
        return html`
      <div class="form-group row">
        <label for="sel_lang_excel" class="col-sm-2 col-form-label">Sprachauswahl</label>
        <div class="col-sm-10">
          <select style="min-width: 150px;" name="languages" id="sel_lang_excel" class="custom-select form-control" @change="${(e) =>
                      (this.excelExportOptions.language = e.target.value)}">
            <option value="all">Alle Sprachen</option>
            ${langOptions}
          </select>
        </div>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" @change="${(e) =>
                      (this.excelExportOptions.assets = e.target.checked)}" />
        <label class="form-check-label" for="assets">Mediendateien exportieren</label>
      </div>
      <div class="form-group row">
        <div class="col-sm-10 pt-3">
          <button class="btn btn-primary" @click="${this.exportExcel}">
            <i class="mdi mdi-download"></i>Export
          </button>
        </div>
      </div>`;
    }

    renderCompetences() {
        console.log("Render sections", JSON.stringify(this.#expandedSections));
        return this.booklet.competences.map((c, idx) => {
          return html`
            <competence-container ?expanded=${this.#expandedSections.has(idx)} @toggle=${this.toggleSection}
              id="${c.id}"></competence-container>`;
        });
    }

    render() {
        if (!this.ready) return null;
        if (!this.user) {
            return html`Nicht eingeloggt -> Bitte einloggen!`;
        }
        if (!this.booklet) 
          return html`Loading ...`;
        return html` 
        <div class="row" id="breadcrumb">
          ${this.renderBreadcrumb()}
        </div>
        <div class="row my-2">${this.renderExport()}</div>
        <div class="row my-2">${this.renderMetadata()}</div>
        <div class="row my-2">${this.renderLanguages()}</div>
        <div class="row my-2">
          <div class="col-3">Handlungsfelder</div>
          <div class="col-3">
            <add-competence booklet-id=${this.id}></add-competence>
          </div>
        </div>
        <div class="row">
          <div id="competences" class="container">
            ${this.renderCompetences()}
          </div>
        </div>`;
    }
}

window.customElements.define("booklet-edit", BookletEdit);
