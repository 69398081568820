import { html, LitElement } from "lit-element";

export default class BookletCard extends LitElement {
    static get properties() {
        return {
            booklet: { type: Object }
        };
    }
    constructor() {
        super();
        /** @type {{id: string, berufId: number, title: string, details: string, tags: [string]}} */
        this.booklet = null;
    }

    //No shadow root currently
    createRenderRoot() {
        return this;
    }

    renderDetails() {
        let text = this.booklet.details;
        if(!text)
            return null;
        if(text.length > 100)
            text = text.substring(0, 97) + "...";
        return html`<div class="card-text py-0">${text}</div>`;

    }

    renderTags() {
        if(!this.booklet.tags || this.booklet.tags.length == 0)
            return null;
        return html`
        <div class="card-text y-0">
            Tags: ${this.booklet.tags.map(t => html`<span class="m-1 badge badge-info">${t}</span>`)}
        </div>`;
    }

    render() {
        let formattedTitle = String(this.booklet.berufId).padStart(2, "0") + ` - ${this.booklet.title}`;
        if(!this.booklet.berufId)
            formattedTitle = this.booklet.title;
        return html`
        <div class="card" id="${formattedTitle}">
            <div class="card-body">
                <h5 class="card-title">
                    <a href="/booklet/${this.booklet.id}/edit">${formattedTitle}</a>
                </h5>
                ${this.renderDetails()}
                ${this.renderTags()}
            </div>
        </div>`;
    }
}

window.customElements.define("booklet-card", BookletCard);