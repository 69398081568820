import GraphQLHelper from "../service/graphql";
import { html } from "lit-element";
import StoreElement from "./StoreElement";
import * as actions from "../service/actionTypes";
// import { bindActionCreators } from "redux";
// import { BaseActions } from "gridjs/dist/gridjs.modern";

class VersionSelector extends StoreElement {
    static get properties() {
        return {
            versions: {attribute: false},
            selectedVersion: {type: String},
            revision: {type: String},
            enabled: {type: Boolean}
        };
    }

    constructor() {
        super();
        this.versions = [];
        this.enabled = false;
        this.loaded = false;
    }

    createRenderRoot() {
        return this;
    }

    storeChanged() {
        const { version, user, token } = this.store.getState();
        if(!this.loaded && token) {
            //we need to fetch data
            this.requestData();
            this.loaded = true;
            return;
        }
        this.enabled = user.rights.query;
        this.selectedVersion = version;
    }

    async changeVersion(newVersion) {
        console.log(`Loading DeCoS in version ${newVersion}`);
        //TODO: needs to be updated
        this.store.dispatch({type: actions.CHANGE_VERSION, version: newVersion });
    }

    async requestData() {
        let res = await GraphQLHelper.query(`{ versions currentVersion }`);
        if(res.error) {
            console.warn(res.error);
            this.enabled = false;
            return;
        }
        if(!res.data.versions || !res.data.versions.length) {
            console.warn("No versions found -> API did not respond as expected");
            return;
        }
        //we have some data
        //create the internal list
        const { version } = this.store.getState();
        this.versions = res.data.versions.sort((a,b) => (a<b) ? 1 : -1);
        this.selectedVersion = version ?? "master";
        this.revision = res.data.currentVersion;
    }

    render() {
        if(!this.enabled)
            return null;
        return html`
        <select class="form-control form-control-sm" id="version" @change=${(e) => {this.changeVersion(e.target.value); }} class="custom-select custom-select-sm" style="margin-right: 10px;">
            ${this.versions.map(opt => html`<option value="${opt}" ?selected=${this.selectedVersion === opt}>${opt}</option>`)}
        </select>`;
    }
}

window.customElements.define("version-selector", VersionSelector);

export class VersionCreator extends StoreElement {
    connectedCallback() {
        super.connectedCallback();
        this.querySelector("form").addEventListener("submit", (e) => {
            e.preventDefault();
            this.createVersion(e.target.elements["versionName"].value);
        });
    }

    createRenderRoot() {
        return this;
    }

    storeChanged() {
        const { user } = this.store.getState();
        this.querySelector("button#versionModalButton").hidden = !(user.rights.write);
    }

    async createVersion(name) {
        let res = await GraphQLHelper.query(`mutation { createVersion(name: "${name}") }`);
        if(res.error) {
            console.warn(res.error);
            alert(`Fehler beim schreiben der Version\n\nStatus: ${res.error.status}`);
            return false;
        }
        //hide the modal
        //TODO: remove jquery
        $(this.querySelector(".modal")).modal("hide"); //eslint-disable-line no-undef
        //reload the versions
        document.dispatchEvent(new CustomEvent("versions:reload"));
        return true;

    }
}

window.customElements.define("version-creator", VersionCreator);