import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import cloneDeep from "lodash-es/cloneDeep";
import * as actions from "./actionTypes";
import * as bookletFn from "./thunks/booklet";
import * as competenceFn from "./thunks/competences";
import * as itemFn from "./thunks/items";
import * as screenFn from "./thunks/screens";
import * as languageFn from "./thunks/languages";
import GraphQLHelper from "./graphql";

const defaultUser = {
    rights: {
        query: false,
        write: false
    }
};

const initialState = {
    user: { ...defaultUser },
    token: null,
    refreshToken: null,
    booklets: [],
    bookletsFetched: false,
    booklet: null,
    competences: {
        data: [],
        fetched: false
    },
    item: null,
    items: [],
    screen: null,
    screens: [],
    languages: [],
    dragTarget: null,
    version: globalThis.localStorage.getItem("version") ?? "master",
    exportBooklets: {
        booklets: [],
        fetched: false
    },
    totalItemCount: 0
};

function reducer(state = initialState, action) {
    console.log(`Reducer runs action ${action.type}`);
    switch (action.type) {
        case actions.LOGIN: {
            globalThis.localStorage.setItem("token", action.token);
            globalThis.localStorage.setItem("refreshToken", action.refreshToken);
            let decoded = jwt_decode(action.token); //eslint-disable-line no-undef
            let user = { ...defaultUser };
            for (let scope of decoded.scopes) {
                if (scope.name == globalThis.IAM_SCOPE && !!(scope.rights)) {
                    user.rights = { ...defaultUser.rights, ...scope.rights };
                    break;
                }
            }
            return {
                ...initialState,
                version: state.version,
                user,
                token: action.token,
                refreshToken: action.refreshToken
            };
        }
        case actions.LOGOUT: {
            globalThis.localStorage.removeItem("token");
            globalThis.localStorage.removeItem("refreshToken");
            return { ...initialState };
        }
        case actions.ADD_ITEM_TO_COMPETENCE: {
            let booklet = JSON.parse(JSON.stringify(state.booklet));
            let competence = booklet.competences.find(e => e.id === action.addedItem.competence);
            competence.items.push({ item: action.addedItem.element, wrong: null, correct: null, countForProgress: 0 });
            competence.items.sort();
            competence.changed = true;
            return { ...state, booklet };
        }
        case actions.MOVE_ITEM_IN_COMPETENCE: {
            if (state.dragTarget.element === action.before.element || state.dragTarget.competence !== action.before.competence) {
                return state;
            }
            let booklet = JSON.parse(JSON.stringify(state.booklet));
            let competence = booklet.competences.find(e => e.id === state.dragTarget.competence);
            let element = competence.items.find(seq => seq.item.id === state.dragTarget.element);
            let tgtIdx = competence.items.findIndex(seq => seq.item.id === action.before.element);
            let newItems = competence.items.filter(seq => seq.item.id !== state.dragTarget.element);
            competence.items = [
                ...newItems.splice(0, tgtIdx),
                element,
                ...newItems
            ];
            //mark it as changed
            competence.changed = true;
            return { ...state, booklet };
        }
        case actions.REMOVE_ITEM_FROM_COMPETENCE: {
            let booklet = JSON.parse(JSON.stringify(state.booklet));
            let competence = booklet.competences.find(e => e.id === action.removedItem.competence);
            let newItems = competence.items.filter(seq => seq.item.id !== action.removedItem.element);
            competence.items = [...newItems];
            competence.changed = true;
            return { ...state, booklet };
        }
        case actions.ADD_SCREEN_TO_COMPETENCE: {
            let booklet = JSON.parse(JSON.stringify(state.booklet));
            let competence = booklet.competences.find(e => e.id === action.addedScreen.competence);
            competence.screens.push(action.addedScreen.element);
            let addedScreen = competence.screens.find(e => e.id === action.addedScreen.element.id);
            addedScreen.added = true;
            competence.changed = true;
            return { ...state, booklet };
        }
        case actions.REMOVE_SCREEN_FROM_COMPETENCE: {
            let booklet = JSON.parse(JSON.stringify(state.booklet));
            let competence = booklet.competences.find(e => e.id === action.removedScreen.competence);
            let removedScreen = competence.screens.find(e => e.id === action.removedScreen.element);
            removedScreen.removed = true;
            competence.changed = true;
            return { ...state, booklet };
        }
        case actions.ADD_SECTION: {
            let competences = cloneDeep(state.competences);
            competences.data.push(action.competence);
            return { ...state, competences };
        }
        case actions.CHANGE_VERSION: {
            globalThis.localStorage.setItem("version", action.version);
            //TODO: evil / inefficient!!! -> do the reload dynamically
            window.location.reload();
            return { ...state, version: action.version };
        }
        case actions.CHANGE_STATE:
        default:
            return {
                ...state,
                ...action?.newState
            };
    }
}

const store = createStore(reducer, compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : args => args));

store.thunks = {
    ...bookletFn,
    ...competenceFn,
    ...itemFn,
    ...screenFn,
    ...languageFn
};

store.actions = actions;

//set store in graphql helper -> TODO: move into own index later
GraphQLHelper.store = store;

export default store;