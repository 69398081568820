import { html } from "lit-element";
import StoreElement from "../components/StoreElement";
import cloneDeep from "lodash-es/cloneDeep";
import GraphQLHelper from "../service/graphql";

class ItemDetails extends StoreElement {
    static get properties() {
        return {
            id: { type: String },
            item: {type: Object},
            error: { type: String, attribute: false },
            saved: { type: Boolean, attribute: false },
            labels: { type: Object }
        };
    }

    constructor() {
        super();
        this.editable = false;
        this.id = null;
        this.language = "de-DE";
        this.oldItem = null;
        this.error = null;
        this.saved = false;
        this.labels = {};
    }

    createRenderRoot() {
        return this;
    }

    connectedCallback() {
        super.connectedCallback();
        (async() => {
            let res = await GraphQLHelper.query(`query {
                settings(key: "apollo_labels")
            }`);
            this.labels = res.data.settings?.labels;
            this.store.dispatch(this.store.thunks.getItemById(this.id, this.language));
        })();
    }

    storeChanged() {
        if (!this.id) {
            return;
        }
        const state = this.store.getState();
        const item = state.item;
        this.item = cloneDeep(item);
        if (!this.oldItem) {
            //only store for the first time, so that we can undo it
            this.oldItem = cloneDeep(this.item);
        }
        this.editable = state.user.rights.write && state.version === "master";
        this.requestUpdate();
    }

    selectLanguage(e) {
        this.language = e.target.value;
        //reload data
        this.store.dispatch(this.store.thunks.getItemById(this.id, this.language));
    }

    renderRawData() {
        let list = [];
        for (let [k, v] of Object.entries(this.item.details.rawData)) {
            if (v == null)
                continue;
            if (typeof v === "object")
                v = JSON.stringify(v);
            let kDisplay = k;
            if (this.labels)
                kDisplay = this.labels[k] ?? k;
            list.push(html`
            <tr>
                <td width="25%">${kDisplay}</td>
                <td>
                    <data-input .key=${k} .value=${v} @onChangeData=${this.onChangeData} .editable=${this.editable}></data-input>
                </td>
            </tr>`);
        }
        return html`<table class="table">${list}</table>`;
    }

    undo() {
        this.changeState({ item: this.oldItem });
    }

    onChangeData(e) {
        try {
            let oldValue;
            let newValue;
            if (e.target.firstElementChild.name){
                oldValue = this.oldItem.details.rawData[e.target.firstElementChild.name];
                newValue = e.target.firstElementChild.value;
                this.item.details.rawData[e.target.firstElementChild.name] = e.target.firstElementChild.value;
            } else if (e.target.firstElementChild.input.name){
                oldValue = this.oldItem.details.rawData[e.target.firstElementChild.input.name];
                newValue = e.target.firstElementChild.input.value;
                this.item.details.rawData[e.target.firstElementChild.input.name] = e.target.firstElementChild.input.value;
            }
            //only change if we actually changed the value
            if (oldValue === newValue) {
                this.item.changed = false;
                this.requestUpdate();
                return;
            }
            this.item.changed = true;
            this.changeState({ item: cloneDeep(this.item) });
        } catch (e) {
            console.error("Failed to set value", e);
        }
    }

    async saveRawData() {
        if (!this.item.changed) {
            return;
        }
        let rawData = cloneDeep(this.item.details.rawData);
        //we have all the data in this.item!
        if (this.language == "fr-FR") {
            //convert all rawData entries with &nbsp; to 0x00A0
            for (let [k, v] of Object.entries(rawData)) {
                if (typeof v === "string") {
                    rawData[k] = v.replaceAll("&nbsp;", String.fromCharCode(0x00A0));
                }
            }
        }
        this.store.dispatch(this.store.thunks.changeItemRawData(this.item.id, this.language, rawData));

        //clear undo
        this.oldItem = null;
        this.saved = true;
        setTimeout(() => this.saved = false, 5000);
    }

    render() {
        if (!this.item) {
            return;
        }
        let buttons = html`
            <button ?disabled=${!this.item.changed} @click="${this.saveRawData}" ?hidden=${!this.editable}
                class="btn btn-sm btn-outline-danger" data-toggle="tooltip" title="Änderungen übernehmen">
                <i class="mdi mdi-content-save-alert"></i>
            </button>
            <button ?disabled=${!this.item.changed} @click="${this.undo}" ?hidden=${!this.editable}
                class="btn btn-sm btn-outline-info" data-toggle="tooltip" title="Änderungen Rückgängig machen">
                <i class="mdi mdi-undo"></i>
            </button>
            <add-button .labels=${this.labels} .language=${this.language}></add-button>
        `;
        return html`
        <div class="container">
            <div class="row">
                <nav class="col">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/items">Items</a></li>
                        <li class="breadcrumb-item">
                            ${this.item.title}
                        </li>
                    </ol>
                </nav>
            </div>
            <div class="form-group row">
                <div class="col-2 label">Sprachen</div> 
                <div class="col-2">
                    <select id="languages" class="form-control" @change=${this.selectLanguage}>
                        ${this.item.languages.map(l => html`<option ?selected=${this.language === l}>${l}</option>`)}
                    </select>                       
                </div>              
            </div>
            <div class="form-group row">
                <div class="col-2 label">Vorschau</div>
                <div class="col">
                    <item-preview .itemid=${this.id}></item-preview>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-2 label">
                    Rohdaten:
                </div>
                <div class="col mr-2 mt-1">
                    ${buttons}
                </div>
            </div>
            <div class="row">
                <div ?hidden="${!this.saved}" class="col alert alert-success">
                    Item erfolgreich gespeichert
                </div>
            </div>
            <div class="row">
                <div ?hidden="${!this.error}" class="col alert alert-danger">
                    Fehler: ${this.error}
                </div>
            </div>
            <div class="form-group row">
                <div class="col">
                    ${this.renderRawData()}
                </div>
            </div>
            <hr />
        </div>`;
    }
}

window.customElements.define("item-details", ItemDetails);