import { html } from "lit-element";
import StoreElement from "../components/StoreElement";
import cloneDeep from "lodash-es/cloneDeep";

class ScreenDetails extends StoreElement {
  static get properties() {
    return {
      id: { type: String },
      screen: { type: Object },
      error: { type: String, attribute: false },
      saved: { type: Boolean, attribute: false },
    };
  }

  constructor() {
    super();
    this.id = null;
    this.language = "de-DE";
    this.oldScreen = null;
    this.error = null;
    this.saved = false;
    this.editable = false;
  }

  createRenderRoot() {
    return this;
  }

  connectedCallback() {
    super.connectedCallback();
    this.store.dispatch(this.store.thunks.getScreenById(this.id));
  }

  storeChanged() {
    if (!this.id) {
      return;
    }
    const { screen, user, version } = this.store.getState();
    this.screen = cloneDeep(screen);
    if (!this.oldScreen) {
      //only store for the first time, so that we can undo it
      this.oldScreen = cloneDeep(screen);
    }
    this.editable = user.rights.write && version == "master";
    this.requestUpdate();
  }

  undo() {
    this.changeState({ screen: this.oldScreen });
  }

  onChangeData(e) {
    try {
      //only change if we actually changed the value
      if (this.oldScreen.content === e.target.firstElementChild.value) {
        this.screen.changed = false;
        this.requestUpdate();
        return;
      }
      this.screen.content = e.target.firstElementChild.value;
      this.screen.changed = true;
      this.changeState({ screen: this.screen });
    } catch (e) {
      console.error("Failed to set value", e);
    }
  }

  async saveScreenContent() {
    if (!this.screen.changed) {
      return;
    }
    if (this.screen.language == "fr-FR") {
      //convert content with &nbsp; to 0x00A0
      if (typeof this.screen.content === "string") {
        this.screen.content = this.screen.content.replaceAll(
          "&nbsp;",
          String.fromCharCode(0x00a0)
        );
      }
    }
    this.store.dispatch(
      this.store.thunks.changeScreenContent(this.screen.id, this.screen.content)
    );
    //clear undo
    this.oldScreen = null;
    this.saved = true;
    setTimeout(() => (this.saved = false), 5000);
  }

  renderScreen() {
    return html` <table class="table">
      <tr>
        <td width="25%">Sprache</td>
        <td>${this.screen.language}</td>
      </tr>
      <tr>
        <td width="25%">Inhalt</td>
        <td>
          <data-input .key="content" .value=${this.screen.content} @onChangeData=${this.onChangeData} .editable=${this.editable}></data-input>
        </td>
      </tr>
    </table>`;
  }

  render() {
    console.log("render() method");
    if (!this.screen) {
      return;
    }
    console.log("Rendering");
    let buttons = html`
      <button
        ?disabled=${!this.screen.changed} @click="${this.saveScreenContent}" ?hidden=${!this.editable}
        class="btn btn-sm btn-outline-danger" data-toggle="tooltip" title="Änderungen übernehmen">
        <i class="mdi mdi-content-save-alert"></i>
      </button>
      <button
        ?disabled=${!this.screen.changed} @click="${this.undo}" ?hidden=${!this.editable}
        class="btn btn-sm btn-outline-info" data-toggle="tooltip" title="Änderungen Rückgängig machen">
        <i class="mdi mdi-undo"></i>
      </button>
    `;
    return html` <div class="container">
      <div class="row">
        <nav class="col">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/screens">Screens</a></li>
            <li class="breadcrumb-item">${this.screen.name}</li>
          </ol>
        </nav>
      </div>
      <div class="form-group row">
        <div class="col-2 label">Inhalt</div>
        <div class="col mr-2 mt-1">${buttons}</div>
      </div>
      <div class="row">
        <div ?hidden="${!this.saved}" class="col alert alert-success">
          Screen erfolgreich gespeichert
        </div>
      </div>
      <div class="row">
        <div ?hidden="${!this.error}" class="col alert alert-danger">
          Fehler: ${this.error}
        </div>
      </div>
      <div class="form-group row">
        <div class="col">${this.renderScreen()}</div>
      </div>
      <hr />
    </div>`;
  }
}

window.customElements.define("screen-details", ScreenDetails);
