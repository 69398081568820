import { html } from "lit-element";
import StoreElement from "../../components/StoreElement";

class SkillsInputs extends StoreElement {
    static get properties() {
        return {
            data: {type:Array},
            module: { type: Object },
            competence: { type: Object },
            escoId: { type: String }
        };
    }

    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();
        this.data = null;
        this.reset();
        this.getModules();
    }

    async fetchData(url) {
        const res = await fetch(url);
        if (res.error) {
            console.log(res.error);
            return [];
        } else {
            return await res.json();
        }
    }

    async getModules() {
        this.data = await this.fetchData("/lib/bst_softskills.json");
    }

    selectModule(e) {
        let index = e.target.selectedIndex - 1;
        let module = this.data[index];
        if (!module) {
            this.reset();
            return;
        }
        this.reset();
        this.module = module;
        this.renderRoot.querySelector("#esco").selectedIndex = 0;
    }

    selectCompetence(e) {
        let index = e.target.selectedIndex - 1;
        this.competence = this.module?.kompetenzen[index];
        this.escoId = this.module?.kompetenzen[index]?.escoId;
    }

    get skills() {
        return {
            esco: this.competence?.name,
            idesco: this.escoId,
            module: this.module?.name
        };
    }
    
    createRenderRoot() {
        return this;
    }

    storeChanged() { }
    
    reset() {
        this.module = null; 
        this.competence = null;
        this.escoId = null; 
        }

    render() {
        return html`
        <div class="structure" >
            <div class="mycolumn mylabel span1to2">
                <label>Softskill Modul</label>
            </div> 
            <div class="span2to10">
                <select class="select" id="module" name="module" size="1" @change=${this.selectModule}>
                <option value="none"></option>
                ${this.data?.map(e => html`<option value=${e.id} ?selected=${e.id == this.module?.id}>${e.name}</option>`)}
                </select>
            </div>
            <div class="mycolumn mylabel span1to2">
                <label>EscoSkills</label>
            </div>
            <div class="span2to10">
                <select class="select" type="text" id="esco" name="esco" @change=${this.selectCompetence}>
                <option value="none"></option>
                    ${this.module?.kompetenzen.map(e => html`<option value=${e.escoId} ?selected=${e.escoId == this.competence?.escoId}>${e.name}</option>`)}
                </select>
            </div>
            <div class="mycolumn mylabel span1to2">
                <label>EscoID</label>
            </div>
            <div class="span2to10">
                <input class="myinput" type="text" id="idesco" name="idesco" readonly .value=${this.escoId}>
            </div>
        </div>
        `;
    }
}


window.customElements.define("skills-inputs", SkillsInputs);
