import { LitElement } from "lit-element";
import store from "../service/store";
import * as actions from "../service/actionTypes";

export default class StoreElement extends LitElement {
    constructor() {
        super();
        this.store = store;
    }

    connectedCallback() {
        super.connectedCallback();
        this._unsub = store.subscribe(this.storeChanged.bind(this));
        //call storeChanged
        this.storeChanged();
    }

    disconnectedCallback() {
        this._unsub?.();
        super.disconnectedCallback();
    }

    changeState(newState) {
        store.dispatch({ type: actions.CHANGE_STATE, newState });
    }

    storeChanged() {
        console.error("Unhandled storeChanged occured");
    }
}