import { html } from "lit-element";
import GraphQLHelper from "../service/graphql";
import StoreElement from "../components/StoreElement";

export default class BookletsList extends StoreElement {
    static get properties() {
        return {
            filter: { type: String },
            booklets: { type: Array, attribute: false },
            newModal: { type: Object},
            showNewBooklet: { type: Boolean, attribute: false }
        };
    }

    constructor() {
        super();
        this.booklets = [];
        this.showNewBooklet = false;
        this.filter = "";
        this.resetModal();
    }

    //No shadow root currently
    createRenderRoot() {
        return this;
    }

    resetModal() {
        this.newModal = {
            name: null,
            berufId: null,
            testHeftName: null,
            testHeftId: null,
            tags: null,
            error: null
        };
    }


    storeChanged() {
        console.log("State changed");
        const state = this.store.getState();
        //check if we need to fetch the data
        if (!state.bookletsFetched && state.token) {
            this.store.dispatch(this.store.thunks.loadBooklets());
        }
        this.showNewBooklet = state.user.rights?.write && state.version === "master";
        this.booklets = state.booklets;
    }

    /**
     * Helper function to filter out the booklets based on the current filter
     * @param {Booklet} b 
     * @returns {boolean} true if in list, false otherwise
     */
    bookletFilter(b) {
        if (this.filter.length < 2)
            return true;
        return b.title.toLowerCase().includes(this.filter.toLowerCase());
    }

    async createBooklet() {
        this.renderRoot.querySelector("#newBookletModal #name").classList.remove("is-invalid");
        //read data from form
        this.newModal = {
            name: this.renderRoot.querySelector("#newBookletModal #name").value,
            berufId: this.renderRoot.querySelector("#newBookletModal #berufId").value,
            testHeftId: this.renderRoot.querySelector("#newBookletModal #testHeftId").value,
            testHeftName: this.renderRoot.querySelector("#newBookletModal #testHeftName").value,
            details: this.renderRoot.querySelector("#newBookletModal #details").value,
            tags: this.renderRoot.querySelector("#newBookletModal #tags").value?.trim()
        };
        if(this.newModal.tags !== "") {
            this.newModal.tags = this.newModal.tags.split(",").map(e => e.trim());
        }
        if(this.newModal?.name == "") {
            this.newModal.error = "Fehler bei der Validierung";
            this.renderRoot.querySelector("#newBookletModal #name").classList.add("is-invalid");
            return false;
        }
        if(!this.newModal.berufId) {
            this.newModal.berufId = null;
        } else {
            this.newModal.berufId = parseInt(this.newModal.berufId);
        }
        console.log("Creating booklet with data", this.newModal);
        let res = await GraphQLHelper.query(
            `mutation createBookletCall($title: String!, $berufId: Int, $testHeftId: String, $details: String, $testHeftName: String, $languages: [ID], $tags: [String!]){
                createBooklet(title: $title, berufId: $berufId, testHeftId: $testHeftId, details: $details, testHeftName: $testHeftName, languages: $languages, tags: $tags){ id }}`,
            {
                title: this.newModal.name,
                berufId: this.newModal.berufId,
                testHeftId: this.newModal.testHeftId,
                testHeftName: this.newModal.testHeftName,
                details: this.newModal.details,
                languages: ["de-DE"],
                tags: this.newModal.tags
            }
        );
        if (res.error) {
            console.warn(res.error);
            this.newModal.error = `Fehler beim erstellen des Booklets\n\nStatus: ${res.error.status}`;
            return false;
        }
        this.resetModal();
        // eslint-disable-next-line no-undef
        $("#newBookletModal").modal("hide");
        //data has changed => reload from graphql
        this.store.dispatch(this.store.thunks.loadBooklets());
        return true;
    }
    
    renderTopBar() {
        return html`
        <div class="row my-2">
            <div class="col">
                <form class="form-inline form" action="?">
                    <div class="input-group">
                        <input type="search" .value=${this.filter} @keyup=${(e) => { this.filter = e.target.value; }} placeholder="Suche ..." name="filter" id="filter" class="form-control">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <i class="mdi mdi-magnify" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            ${this.renderNewBooklet()}
        </div>
        <hr class="my-2">`;
    }

    renderBooklets() {
        if (!this.booklets || this.booklets.length == 0) {
            return html`<div class="col-12 py-2">Keine Booklets gefunden</div>`;
        }
        return html`<div class="row my-2">
        ${this.booklets.filter(this.bookletFilter, this).map(b => html`<booklet-card class="col-6 py-2" .booklet=${b}></booklet-card>`)}
        </div>`;
    }

    renderNewBooklet() {
        if(!this.showNewBooklet)
            return null;
        return html`
        <div class="col">
            <button class="btn btn-info" data-toggle="modal" data-target="#newBookletModal">Neues Booklet</button>
        </div>
        <div class="modal fade" id="newBookletModal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Neues Booklet erstellen</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        ${this.newModal.error && html`<div class="alert alert-danger">${this.newModal.error}</div>`}
                        <form>
                            <div class="form-group">
                                <label for="name">Name / Titel</label>
                                <input class="form-control" type="text" name="name" id="name" .value="${this.newModal.name ?? ""}" placeholder="Titel" required/>
                                <div class="invalid-feedback">
                                    Ein Titel muss angegeben werden
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="berufId">Beruf Id (Optional)</label>
                                <input class="form-control" type="text" name="berufId" id="berufId" .value="${this.newModal.berufId ?? ""}" placeholder="01" />
                            </div>
                            <div class="form-group">
                                <label for="testHeftName">Testheft Name (Optional)</label>
                                <input class="form-control" type="text" name="testHeftName" id="testHeftName" .value="${this.newModal.testHeftName ?? ""}" placeholder="Testheft 2" />
                            </div>
                            <div class="form-group">
                                <label for="testHeftId">Testheft Id (Optional)</label>
                                <input class="form-control" type="text" name="testHeftId" id="testHeftId" .value="${this.newModal.testHeftId ?? ""}" placeholder="123" />
                            </div>
                            <div class="form-group">
                                <label for="details">Details (Optional)</label>
                                <textarea class="form-control" name="details" id="details" .value="${this.newModal.details ?? ""}" placeholder="Eine kurze Beschreibung des Booklets"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="tags">Tags (Optional)</label>
                                <input class="form-control" type="text" name="tags" id="tags" .value="${this.newModal.tags ?? ""}" placeholder="Schlagwörter"></textarea>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Verwerfen</button>
                        <button type="button" class="btn btn-primary" @click=${this.createBooklet}>Erstellen</button>
                    </div>
                </div>
            </div>
        </div>`;
    }

    render() {

        return [
            this.renderTopBar(),
            this.renderBooklets()
        ];
    }
}

window.customElements.define("booklets-list", BookletsList);