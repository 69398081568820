import StoreElement from "../components/StoreElement";
import { BaseComponent, h, PluginPosition, Grid } from "gridjs/dist/gridjs.modern.js";

class PgLimit extends BaseComponent {
    render() {
        let limit = this.config.pagination.limit;
        let on_change = (e) => {
            let _val = e.target.value;
            if (!isNaN(_val)) {
                let _conf = { pagination: { limit: parseInt(_val) } };
                let dataGrid = this.config.instance;
                dataGrid.updateConfig(_conf);
                // let _tmp = dataGrid.config.plugin;
                dataGrid.forceRender();
            }
        };
        on_change.bind(this);

        return h("select", {
            className: "form-control",
            style: { width: "auto", display: "inline", marginLeft: "10px", height: "calc(1.66em + .75rem + 2px)" },
            onChange: on_change
        }, [
            h("option", { value: "-", selected: "selected" }, limit + " Items / Seite"),
            h("option", { value: 10 }, "10"),
            h("option", { value: 25 }, "25"),
            h("option", { value: 50 }, "50"),
            h("option", { value: 100 }, "100")
        ]);
    }
}

export class ItemList extends StoreElement {
    static get properties() {
        return {
            items: { type: Array, attribute: false }
        };
    }
    constructor() {
        super();
        this.grid = null;
        this.items = [];
    }

    connectedCallback() {
        super.connectedCallback();
        this.store.dispatch(this.store.thunks.getItemOverview());
    }

    createRenderRoot() {
        return this;
    }

    storeChanged() {
        this.items = this.store.getState().items;
    }

    render() {
        const conf = {
            columns: [
                {
                    id: "id",
                    hidden: true,
                },
                {
                    id: "title",
                    name: "Titel",
                    formatter: (cell, row) => {
                        return h("a", { href: `/item/${row.cells[0].data}` }, cell);
                    }
                },
                {
                    id: "type",
                    name: "Typ"
                },
                {
                    id: "usedIn",
                    name: "Verwendet in",
                    width: "50%",
                    formatter: (cell) => {
                        return cell.map((e) => h("a", { href: `/booklet/${e.id}/edit` }, e.title));
                    }
                }
            ],
            data: this.items,
            pagination: {
                limit: 50
            },
            search: true,
            sort: true,
            fixedHeader: true,
            plugins: [
                {
                    id: "pgLimit",
                    component: PgLimit,
                    position: PluginPosition.Header,
                }
            ]
        };
        if (!this.grid) {
            this.grid = new Grid(conf);
            this.replaceChildren();
            this.grid.render(this);
        } else {
            this.grid.updateConfig(conf);
            this.replaceChildren();
            this.grid.forceRender();
        }
    }
}

window.customElements.define("item-list", ItemList);