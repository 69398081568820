import { html } from "lit-element";
import StoreElement from "../../components/StoreElement";

class BookletExportCode extends StoreElement {
    static get properties() {
        return {
            booklets: { type: Object },
            expanded: { type: Boolean }
        };
    }

    get version() {
        let vs = document.querySelector("version-selector");
        console.log(vs.selctedVersion, vs.revision, vs.selectedRevision);
        return vs.selectedVersion === "master" ? vs.revision : `version/${vs.selectedVersion}`;
    }

    createRenderRoot() {
        return this;
    }

    storeChanged() {
        const {exportBooklets} = this.store.getState();
        this.booklets = exportBooklets.booklets.filter(e => e.selected).map(e => { return {booklet: e.id, languages: e.languages}; });
    }

    render() {
        return html`
            <div class="row">
                <div class="col">
                    <style>
                        #json-button[aria-expanded]>span {
                            display: none;
                        }
                        #json-button[aria-expanded=false]>span.show {
                            display: inline-block;
                        }
                        #json-button[aria-expanded=true]>span.hide {
                            display: inline-block;
                        }
                    </style>
                    <button type="button" class="btn btn-link" id="json-button" data-toggle="collapse" data-target="#json" aria-expanded="false" aria-controls="json">
                        <span class="show">Code anzeigen</span>
                        <span class="hide">Code verbergen</span>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col collapse" id="json" aria-labelledby="code-button">
                    <textarea class="form-control" cols=80 rows=20 id="package">${JSON.stringify({version: this.version, booklets: this.booklets}, null, 2)}</textarea>
                </div>
            </div>
        `;
    }
}
window.customElements.define("export-code", BookletExportCode);