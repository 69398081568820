import { html } from "lit-element";
import StoreElement from "../components/StoreElement";
import {version} from "../../../package.json";

export class Impressum extends StoreElement {
    static get properties() {
        return {
            user: { type: Object}
        };
    }
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();
    }

    createRenderRoot() {
        return this;
    }


    storeChanged() {
        let state = this.store.getState();
        this.user = state.user;
        this.requestUpdate();
    }

    render() {
        if(!this.user)
            return null;
        return html`                    
        <div style="margin:2%; max-width: 50em;">
            <p><b>${globalThis.HEADLINE_BRAND} V${version}</b>
                <br />&copy; <a href="https://www.dipf.de/" target="_parent">DIPF</a>. Alle Rechte vorbehalten.
                <br />${globalThis.HEADLINE_BRAND} umfasst computerbasierte Kompetenztests und deren Elementen f&uuml;r die
                Referenzberufe.
            </p>
            <p><b>Software-Lizenzen:</b>
                <br />MIT
                <br />
            </p>
            <p><b>Entwicklung:</b>
                <br />Zentrum f&uuml;r technologiebasiertes Assessment&nbsp;(TBA) am
                <br />DIPF | Leibniz-Institut f&uuml;r Bildungsforschung und Bildungsinformation
                <br />
            </p>
            <p><b>Verantwortlich:</b>
                <br /><a href="https://www.dipf.de/" target="_parent">DIPF | Leibniz-Institut f&uuml;r Bildungsforschung und
                    Bildungsinformation</a>
                <br />Rostocker Stra&szlig;e 6, 60323 Frankfurt am Main
                <br />Tel.: +49 69 24708-0 &bull; Fax: +49 69 24708-444
                <br />Kontakt: <a href="https://www.dipf.de/de/kontakt" target="_parent">https://www.dipf.de/de/kontakt</a>
                <br />Umsatzsteuer-Identifikationsnummer: DE114237569
                <br />
                <br />Das DIPF | Leibniz-Institut f&uuml;r Bildungsforschung und Bildungsinformation ist eine
                rechtsf&auml;hige Stiftung
                des &ouml;ffentlichen Rechts. Das DIPF unterst&uuml;tzt Forschung, Politik und Praxis im Bildungsbereich
                durch
                wissenschaftliche Infrastruktur und vielf&auml;ltige Forschungst&auml;tigkeit. Als Mitglied der
                Leibniz-Gemeinschaft
                verbindet das Institut erkenntnisorientierte Grundlagenforschung mit innovativen Entwicklungsarbeiten und
                Anwendungen
                zum Nutzen der Gesellschaft und ihrer Mitglieder.
            </p>
        </div>`;
    }
}

window.customElements.define("the-impressum", Impressum);

