import GraphQLHelper from "../graphql";
import * as actions from "../actionTypes";

export function getItemOverview() {
    return async(dispatch) => {
        let res = await GraphQLHelper.query(`{
            items {
                id
                title
                type
                usedIn { 
                    id
                    title
                }
            }
        }`);
        if (res.data.items) {
            dispatch({
                type: actions.CHANGE_STATE,
                newState: {
                    items: res.data.items
                }
            });
        }

    };
}
export function getItemPaginated(first, skip) {
    return async(dispatch) => {
        let res = await GraphQLHelper.query(`query($first: Int, $skip: Int) {
            items(first: $first, skip: $skip) {
                id
                title
                type
                usedIn { 
                    id
                    title
                }
            }
            itemCount
        }`, { first: first, skip: skip });
        if (res.data.items) {
            dispatch({
                type: actions.CHANGE_STATE,
                newState: {
                    items: res.data.items,
                    totalItemCount: res.data.itemCount
                }
            });
        }
    };
}
export function getItemById(item_id, item_lang) {
    return async(dispatch) => {
        let res = await GraphQLHelper.query(`query itemByID($id: ID!, $lang: String!) {
            itemById(id: $id) {
                id
                title
                languages
                details(languageId: $lang) {
                    rawData
                }
            }
        }`, { id: item_id, lang: item_lang });
        if (res.data.itemById) {
            dispatch({
                type: actions.CHANGE_STATE,
                newState: {
                    item: res.data.itemById
                }
            });
        }

    };
}

export function changeItemRawData(item_id, item_lang, rawData) {
    return async(dispatch) => {
        let res = await GraphQLHelper.query(
            `mutation updateItemRawData($id: ID!, $lang: String!, $rawData: JSON!)
                  {
                    updateItemRawData(
                        id: $id,
                        language: $lang, 
                        rawData: $rawData
                    )
                  }`,
            {
                id: item_id,
                lang: item_lang,
                rawData: rawData
            }
        );
        if (res.data.updateItemRawData) {
            dispatch(getItemById(item_id, item_lang));
        }
    };
}